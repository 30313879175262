import authHeader from "../../../../services/auth-header";
import { Role, SearchRequest, User } from "./model";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const HOST = process.env.REACT_APP_API_URL;
const ADMIN = "api/admin"
const APIS = {USER:"user", ROLE:"role"}

export const allValuesEmpty = (obj: { [key: string]: any }): boolean => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'string' && obj[key] !== "") {
        return false; // If any string value is not empty, return false
      }
      if (typeof obj[key] === 'boolean' && obj[key] === true) {
        return false; // If any boolean value is true, return false
      }
    }
  }
  return true; // If all values are either empty strings or false booleans, return true
};


export const SearchUserAction = async (formdata: SearchRequest) => {
    const axiosConfig: AxiosRequestConfig = {
        headers: authHeader(),
        method: "GET",
        url: `${HOST}/${ADMIN}/${APIS.USER}`,
        params: {...formdata},
        data: {},

      };
      try {
        const apiCall: AxiosResponse<User[]> = await axios(axiosConfig);
        return apiCall;
      } catch (err) {
        throw err;
      }
};


export const UpdateUserAction = async(formdata:User) =>{

  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${ADMIN}/${APIS.USER}`,
    params: {},
    data: {...formdata},

  };
  try {
    const apiCall: AxiosResponse<User> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
}


export const getRoles = async() =>{
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "GET",
    url: `${HOST}/${ADMIN}/${APIS.ROLE}`,
    params: {},
    data: {},

  };
  try {
    const apiCall: AxiosResponse<Role[]> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
}