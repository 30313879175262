import React from "react";
import Main from "../../elements/public/main";
import { Divider, Grid, Typography } from "@mui/material";
import { User } from "../../services/models/auth-token.model";
import { useSelector } from "react-redux";
import { InstructorVideos, StudentVideos } from "./constraints";

interface VideoConfigProps {
  videoUrl: string;
  title: string;
  allow: string;
}

const VideoComponent: React.FC<{ videoConfig: VideoConfigProps }> = ({
  videoConfig,
}) => {
  const { videoUrl, title, allow } = videoConfig;

  // Generate URL for the iframe

  return (
    <iframe
      src={videoUrl}
      title={title}
      allow={allow}
      allowFullScreen
      className="youtubeVideo"
    ></iframe>
  );
};

const VideoGuide: React.FC = () => {
  const user: User = useSelector((state: any) => state.auth.user);
  return (
    <Main>
      {user && user.roles.includes("ROLE_STUDENT") && (
        <Grid
          container
          flexDirection="column"
          alignItems="stretch"
          alignContent="center"
          justifyContent="flex-start"
          // component={Paper}
          spacing={3}
        >
          <Grid item xs={12} sm={12} lg={6}>
            <Typography variant="h2">Student Guide</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            {StudentVideos.map((config) => (
              <VideoComponent videoConfig={config} />
            ))}
          </Grid>
        </Grid>
      )}
      {user && user.roles.includes("ROLE_INSTRUCTOR") && (
        <Grid
          container
          flexDirection="column"
          alignItems="stretch"
          justifyContent="flex-start"
          alignContent="center"
          // component={Paper}
          spacing={3}
        >
          <Grid item xs={12} sm={12} md={6}>
            <Typography variant="h2">Instructor Guide</Typography>
            <Divider />
          </Grid>

          {InstructorVideos.map((config) => (
            <Grid item xs={12} sm={12} lg={6}>
              <VideoComponent videoConfig={config} />
            </Grid>
          ))}
        </Grid>
      )}
    </Main>
  );
};

export default VideoGuide;
