export interface FormDataProp {
  oldPassword: string;
  newPassword: string;
  renewPassword: string;
}

export const initFormData: FormDataProp = {
  oldPassword: "",
  newPassword: "",
  renewPassword: "",
};
