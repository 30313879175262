import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import authHeader from "../../../../services/auth-header";
import { NameSearchProps, SearchSubmissionRequest, SearchSubmissionResponse } from "./models";

const HOST = process.env.REACT_APP_API_URL;
const ADMIN = "api/admin";
const SUBMISSION = "api/submission"

const APIS = { NAME: "name", ROLE: "role", USER:"user" };
export const getNames = async (name:string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "GET",
    url: `${HOST}/${ADMIN}/${APIS.NAME}`,
    params: {name},
    data: {},
  };
  try {
    const apiCall: AxiosResponse<NameSearchProps[]> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const getSubmissions = async (request:SearchSubmissionRequest) => {
    const axiosConfig: AxiosRequestConfig = {
      headers: authHeader(),
      method: "POST",
      url: `${HOST}/${SUBMISSION}/${APIS.USER}`,
      params: {},
      data: request,
    };
    try {
      const apiCall: AxiosResponse<SearchSubmissionResponse[]> = await axios(axiosConfig);
      return apiCall;
    } catch (err) {
      throw err;
    }
  };
  
