import { Reviews } from "../../../../store/ClassDetails/model";

export type MutalReviewType = "Mutal" | "Optional" | "Unidirectional";

export interface PeerReviewDialogProps {
  open: boolean;
  assignmentId: string;
  setUnsaved: (state: string, status: boolean) => void;
}

export interface ReviewsRowProps {
  row: PeerReviewViewModel;
  handleOpenPreview: (id: string) => void;
}

export interface RandomizeRequest {
  mutalReview: MutalReviewType;
  reviewer_size: number;
  size: number;
}

export const initRandomizeRequest: RandomizeRequest = {
  mutalReview: "Mutal",
  reviewer_size: 1,
  size: 0,
};

export type TabType = "Reviewer" | "Deadlines" | "Reviews";

interface Title {
  title: string;
}
export type PeerReviewItemViewModel = Title & Reviews;
export interface PeerReviewViewModel {
  reviewee: string;
  revieweeId: string;
  pr: PeerReviewItemViewModel[];
}

export interface Preview {
  id: string;
  open: boolean;
}

export const initPreview: Preview = {
  id: "",
  open: false,
};

export interface PreviewDialogProps {
  preview: Preview;
  handleClose: () => void;
}

export interface UsavedChange {
  reviewer: boolean;
  deadline: boolean;
  notification: boolean;
}

export const initUsavedChange: UsavedChange = {
  reviewer: false,
  deadline: false,
  notification:false,
};

export interface NotificationProps{
  open: boolean;
  handleClose: (state: boolean) => void;
  handleDismiss: () => void;
}