import React, { useState, useEffect } from "react";
import { Grid, TextField, IconButton, Paper, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import dayjs, { Dayjs } from "dayjs";

interface DraftSubmissionProps {
  addNewDraft: (
    draftTitle: string,
    draftDeadline: Dayjs,
    draftPointValue: number
  ) => boolean;

  updateDraft: (
    draftTitle: string,
    draftDeadline: Dayjs,
    draftPointValue: number,
    index: number
  ) => boolean;
  timezone: string;
  minDate: Dayjs;
  maxDate: Dayjs;
  draftData: any | undefined;
  draftStatus: (state: boolean) => void;
}

const DraftSubmission: React.FC<DraftSubmissionProps> = ({
  addNewDraft,
  updateDraft,
  draftStatus,
  timezone,
  minDate,
  maxDate,
  draftData,
}) => {
  const defaultState = {
    draftTitle: "",
    draftDeadline: dayjs(null).tz(timezone).startOf("day"),
    draftpointvalue: 0,
    draftIndex: 0,
  };

  const [state, setState] = useState<any>({ ...defaultState });

  useEffect(() => {
    setState(
      draftData === undefined
        ? { ...defaultState }
        : {
            ...defaultState,
            draftTitle: draftData.draftTitle,
            draftDeadline: draftData.draftDeadline,
            draftpointvalue: draftData.draftPointValue,
            draftIndex: draftData.draftIndex,
          }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftData]);

  const handleChange = (e: any) => {
    setState({
      ...state,
      [e.target.id]: e.target.value,
    });
  };

  useEffect(() => {
    if (
      state.draftTitle === "" &&
      (state.draftpointvalue === 0 ||
        state.draftpointvalue === "" ||
        !state.draftpointvalue)
    ) {
      draftStatus(false);
    } else {
      draftStatus(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleSave = () => {
    var response: boolean;
    if (draftData === undefined) {
      response = addNewDraft(
        state.draftTitle,
        state.draftDeadline,
        state.draftpointvalue
      );
    } else {
      response = updateDraft(
        state.draftTitle,
        state.draftDeadline,
        state.draftpointvalue,
        draftData.index
      );
    }

    if (response) {
      setState({ ...defaultState });
      draftStatus(false);
    }
  };

  const handleEndDateChange = (e: any) => {
    setState({ ...state, draftDeadline: e });
  };

  const currentDate = dayjs(null).tz(timezone).startOf("day");

  return (
    <Paper elevation={3} sx={{ p: 3, m: 1 }}>
      <Grid container columns={{ xs: 6, md: 6 }}>
        <Grid item xs={6} md={6}>
          <Typography variant="h6">Draft submission</Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <TextField
            id="draftTitle"
            name="draftTitle"
            label="Draft Title"
            fullWidth
            variant="standard"
            margin="normal"
            onChange={handleChange}
            value={state.draftTitle}
            sx={{ my: { xs: 1, md: 1 } }}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label={`Deadline : ${timezone}`}
              value={state.draftDeadline}
              minDate={minDate.isBefore(currentDate) ? currentDate : minDate}
              maxDate={maxDate}
              onChange={(newValue) => {
                handleEndDateChange(newValue);
              }}
              sx={{ width: "100%" }}
              timezone={timezone}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} md={6}>
          <TextField
            fullWidth
            variant="standard"
            margin="normal"
            type="number"
            label="Point value(%)"
            id="draftpointvalue"
            onChange={handleChange}
            value={state.draftpointvalue}
            inputProps={{ inputMode: "numeric", min: 0, max: 100 }}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <IconButton onClick={handleSave}>
            {draftData === undefined ? (
              <AddCircleIcon sx={{ fontSize: 48 }} />
            ) : (
              <ModeEditIcon sx={{ fontSize: 48 }} />
            )}
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DraftSubmission;
