import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import CopyRight from "../../elements/public/copy-right";
import { LINKS } from "./constants";

const Footer: React.FC = () => {
  const theme = useTheme();
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
      sx={{ mt: -15, pt: 20, backgroundColor: theme.palette.primary.main }}
      minHeight="40vh"
      direction="row"
      wrap="wrap"
    >
      <Grid
        md={3}
        sx={{
          display: { xs: "none", sm: "none", md: "flex" },
          color: "black",
          backgroundImage: "url(" + process.env.PUBLIC_URL + "/logow.png)",
          backgroundRepeat: "no-repeat",

          backgroundSize: "contain",
          backgroundPosition: "center",
        }}
      ></Grid>
      <Grid md={2} sx={{ color: "white" }}>
        <Stack>
          <Typography variant="caption">Quick Links:</Typography>
          {LINKS.map((item) => (
            <Typography
              variant="button"
              component="a"
              href={item.link}
              sx={{ color: "white" }}
              target={item?.target || "_self"}
            >
              {item.caption}
            </Typography>
          ))}
          {/* <Typography variant="button" component="a" href='/termsandconditions' target="_blanc" sx={{color:"white"}}>Terms and Conditions</Typography> */}
        </Stack>
      </Grid>
      <Grid md={2} sx={{ color: "white" }}>
        <Stack>
          <Typography variant="caption">Email Address:</Typography>
          <Typography
            variant="button"
            component="a"
            href="mailto:info@antecedent.us"
            sx={{ color: "white" }}
          >
            info@antecedent.us
          </Typography>
          <Typography variant="caption">Social Media:</Typography>
          <Typography
            variant="button"
            component="a"
            href="https://www.youtube.com/@AntecedentWriting"
            sx={{ color: "white" }}
            target="_blank"
          >
            Youtube
          </Typography>
        </Stack>
      </Grid>
      <Grid
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          flexWrap: "nowrap",
        }}
      >
        <CopyRight sx={{ color: "white" }} id="copyWrite" />
      </Grid>
    </Grid>
  );
};

export default Footer;
