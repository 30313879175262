import React from "react";
// eslint-disable-next-line
import Main from "../../elements/public/main";
import { withRouter } from "../../common/withRouter";
import {
  Alert,
  Avatar,
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import {
  recoverPassword,
  validateRecoveryToken,
} from "../../services/auth.service";

import { passwordRegex } from "../../services/models/patterns.regex";

class RecoverPassword extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      id: this.props.router.params.id,
      email: "",
      username: "",
      password: "",
      repassword: "",
      loading: false,
      successful: false,
      valid: false,
      expiry: 0,
      message: "",
      loaded: false,
    };
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    });

    try {
      const validateToken = await validateRecoveryToken(this.state.id);
      this.setState({
        loading: false,
        valid: true,
        expiry: validateToken.data,
        loaded: true,
      });
    } catch (error: any) {
      const messages =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      this.setState({
        loading: false,
        message: messages,
        loaded: true,
      });
    }
  }

  // TODO add interface
  handleChange(e: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  // TODO add interface
  async handleSubmit(e: any) {
    e.preventDefault();

    if (this.state.password !== this.state.repassword) {
      this.setState({
        message: "passwords do not match.",
        loading: false,
      });
      return;
    }

    this.setState({
      loading: true,
    });

    const { id, username, email, password } = this.state;
    try {
      await recoverPassword(id, email, username, password);
      this.setState({
        successful: true,
        loading: false,
      });
    } catch (error: any) {
      const messages =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      this.setState({
        message: messages,
        loading: false,
      });
    }
  }

  render() {
    return (
      <Main>
        <Container component="main" maxWidth="sm">
          <Paper sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Box
              sx={{
                my: 0,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar
                sx={{ m: 1, bgcolor: "secondary.main", width: 56, height: 56 }}
              >
                <PasswordOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Recover Password or Username
              </Typography>
            </Box>
            {this.state.valid &&
              !this.state.successful &&
              this.state.loaded && (
                <Grid
                  container
                  spacing={3}
                  component="form"
                  onSubmit={this.handleSubmit}
                >
                  <Grid item xs={12}>
                    <Alert severity="success">
                      Your token expires in {this.state.expiry} minutes.
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="email"
                      name="email"
                      label="Email"
                      type="email"
                      fullWidth
                      variant="standard"
                      margin="normal"
                      onChange={this.handleChange}
                      value={this.state.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="username"
                      name="username"
                      label="Username"
                      type="username"
                      fullWidth
                      variant="standard"
                      margin="normal"
                      onChange={this.handleChange}
                      value={this.state.username}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="New Password"
                      type="password"
                      id="password"
                      // value={this.state.password}
                      onChange={this.handleChange}
                      value={this.state.password}
                      variant="standard"
                      inputProps={{ pattern: passwordRegex.source }}
                      helperText="Password must be at least 8 characters and involve at least one of each of the following: uppercase letter, lowercase letter, a number, and a special character (!@$%&)."
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="repassword"
                      label="Confirm Password"
                      type="password"
                      id="repassword"
                      // value={this.state.password}
                      onChange={this.handleChange}
                      value={this.state.repassword}
                      variant="standard"
                      inputProps={{ pattern: passwordRegex.source }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      <span>Set Password</span>
                    </Button>
                  </Grid>
                  {this.state.message && (
                    <Grid item xs={12}>
                      <Alert severity="error">{this.state.message}</Alert>
                    </Grid>
                  )}
                </Grid>
              )}
            {!this.state.valid &&
              !this.state.successful &&
              this.state.loaded && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Alert severity="error">{this.state.message}</Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      href="/ForgetPassword"
                      variant="contained"
                      fullWidth
                    >
                      New Token
                    </Button>
                  </Grid>
                </Grid>
              )}
            {this.state.successful && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Alert severity="success">
                    Your password changed successfully
                  </Alert>
                </Grid>
                <Grid item xs={12}>
                  <Button href="/login" variant="contained" fullWidth>
                    Redirect To Login
                  </Button>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Container>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Main>
    );
  }
}
// TODO check later
// @ts-ignore
export default withRouter(RecoverPassword);
