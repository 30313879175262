import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Box,
  Grid,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import { useSelector } from "react-redux";

import { etcGmtTimezones } from "../../../../services/models/timezones";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import {
  EditClassDetailsProps,
  classLists,
  getClassLevels,
  handleWarning,
  initClassLists,
  initEditClassDetails,
} from "./services";
import { AnyAction, Dispatch } from "redux";
import { UpdateClassDetails } from "../../../../store/ClassDetails/model";
import { updateClassDetailsAction } from "../../../../store/ClassDetails/actions";

dayjs.extend(utc);
dayjs.extend(timezone);

const timezonesProps = {
  options: etcGmtTimezones.map((option) => option),
  getOptionLabel: (option: string) => option,
};

interface EditClassProps {
  handleEditClose: () => void;
  dispatch: Dispatch<AnyAction>;
}

const EditClass: React.FC<EditClassProps> = ({ handleEditClose, dispatch }) => {
  const classDetails: EditClassDetailsProps = useSelector((state: any) => {
    const {
      className,
      classTimeZone,
      endDate,
      id,
      startDate,
      studentLevel,
      subjectArea,
      enrollKey,
      assignments,
    } = state.classDetails;
    return {
      className,
      classTimeZone,
      endDate: dayjs(endDate),
      id,
      startDate: dayjs(startDate),
      studentLevel,
      subjectArea,
      enrollKey,
      studentLevelId: "",
      subjectAreaId: "",
      assignments,
    };
  });

  const handleTimezoneChange = (event: any, e: any | null) => {
    try {
      if (e !== null && e !== "") {
        setFormData({
          ...formData,
          classTimeZone: e,
          startDate: formData.startDate.tz(e).startOf("day"),
          endDate: formData.endDate.tz(e).endOf("day"),
        });
      }
    } catch {}
  };

  const handleStartDateChange = (e: Dayjs | null) => {
    // const maxStartDate =
    if (e !== null) {
      if (e.startOf("day").isAfter(formData.endDate)) {
        handleWarning("Start date can not be after end date", dispatch);
        return;
      }
      setFormData({
        ...formData,
        startDate: e.tz(formData.classTimeZone).startOf("day"),
      });

      //
    }
  };

  const maxStartDate = (): Dayjs => {
    try {
      const sortedAssignments = [...formData.assignments].sort((a, b) =>
        dayjs(a.startDate).isAfter(dayjs(b.startDate)) ? 1 : -1
      );
      const minAssignmentStart = dayjs(sortedAssignments[0]?.startDate);
      return minAssignmentStart;
    } catch {
      return formData.startDate;
    }
  };

  const minEndDate = (): Dayjs => {
    try {
      const sortedAssignments = [...formData.assignments].sort((a, b) =>
        dayjs(a.startDate).isBefore(dayjs(b.startDate)) ? 1 : -1
      );
      const minAssignmentEnd = dayjs(sortedAssignments[0]?.endDate);
      return minAssignmentEnd;
    } catch {
      return formData.startDate;
    }
  };

  const handleEndDateChange = (e: Dayjs | null) => {
    // const maxStartDate =
    if (e !== null) {
      setFormData({
        ...formData,
        endDate: e.tz(formData.classTimeZone).endOf("day"),
      });
    }
  };

  const [formData, setFormData] =
    useState<EditClassDetailsProps>(initEditClassDetails);

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const [classLists, setClassLists] = useState<classLists>(initClassLists);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [levelList, areaList] = await Promise.all([
          getClassLevels("level"),
          getClassLevels("area"),
        ]);
        setClassLists({ levelList: levelList.data, areaList: areaList.data });
      } catch {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    setFormData((prevState) => {
      const subjectAreaMatch = classLists.areaList.find(
        (c) => c.name === classDetails.subjectArea
      );
      const studentLevelMatch = classLists.levelList.find(
        (c) => c.name === classDetails.studentLevel
      );
      // Update formData only if subjectAreaMatch is found
      return {
        ...prevState,
        ...classDetails,
        subjectAreaId: subjectAreaMatch ? subjectAreaMatch.id : "", // or null, or some default value
        studentLevelId: studentLevelMatch ? studentLevelMatch.id : "", // or null, or some default value
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classLists]);

  //   useEffect(() => {
  //     console.log(formData);
  //   }, [formData]);

  const areaProps = {
    options: classLists.areaList,
    getOptionLabel: (option: any) => option.name, // TODO add interface
  };
  const levelProps = {
    options: classLists.levelList,
    getOptionLabel: (option: any) => option.name, // TODO add interface
  };

  const handleAutocompleteChange = (
    event: any,
    newValue: any | null,
    fieldKey: string
  ) => {
    setFormData((prevData) => {
      // Return the old state if newValue is null
      if (newValue === null) {
        return prevData;
      }

      // Update the state with new value and ID otherwise
      return {
        ...prevData,
        [`${fieldKey}`]: newValue.name,
        [`${fieldKey}Id`]: newValue.id,
      };
    });
  };

  const handleSave = async (e: any) => {
    e.preventDefault();
    const request: UpdateClassDetails = {
      id: formData.id,
      classname: formData.className,
      subjectarea: formData.subjectAreaId,
      studentlevel: formData.studentLevelId,
      classStartDate: formData.startDate.toDate(),
      classEndDate: formData.endDate.toDate(),
      classTimeZone: formData.classTimeZone,
    };

    try {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const resposne =await dispatch(updateClassDetailsAction( request, formData.studentLevel, formData.subjectArea));
      handleEditClose();
    } finally {
    }
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      component="form"
      onSubmit={handleSave}
    >
      <Grid item xs={12} lg={8}>
        <Paper elevation={24}>
          <Box sx={{ p: 2, pb: 0, mb: 2, border: "1px solid grey" }}>
            <Grid container spacing={2}>
              <Grid item xs={4} sx={{ bgcolor: "text.hint" }}>
                Class Name
              </Grid>
              <Grid item xs={8}>
                <TextField
                  required
                  id="className"
                  name="className"
                  fullWidth
                  variant="standard"
                  margin="normal"
                  onChange={handleChange}
                  value={formData.className}
                  sx={{ my: { xs: 1, md: 2 } }}
                />
              </Grid>

              <Grid item xs={4} sx={{ bgcolor: "text.hint" }}>
                Subject Area
              </Grid>
              <Grid item xs={8}>
                <Autocomplete
                  {...areaProps}
                  id="subjectArea"
                  autoSelect
                  value={{
                    id: formData.subjectAreaId,
                    name: formData.subjectArea,
                  }}
                  onChange={(event: any, newValue: any | null) => {
                    handleAutocompleteChange(event, newValue, "subjectArea");
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" required />
                  )}
                />
              </Grid>

              <Grid item xs={4} sx={{ bgcolor: "text.hint" }}>
                Student Level
              </Grid>
              <Grid item xs={8}>
                <Autocomplete
                  {...levelProps}
                  id="studentLevel"
                  //   name="subjectarea"
                  autoSelect
                  value={{
                    id: formData.studentLevelId,
                    name: formData.studentLevel,
                  }}
                  onChange={(event: any, newValue: any | null) => {
                    handleAutocompleteChange(event, newValue, "studentLevel");
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" required />
                  )}
                />
              </Grid>

              <Grid item xs={4} sx={{ bgcolor: "text.hint" }}>
                Start Date
              </Grid>
              <Grid item xs={8}>
                {formData.classTimeZone !== "" && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={formData.startDate}
                      maxDate={maxStartDate()}
                      onChange={handleStartDateChange}
                      sx={{ width: "100%" }}
                      format="YYYY-MM-DD"
                    />
                  </LocalizationProvider>
                )}
              </Grid>
              <Grid item xs={4} sx={{ bgcolor: "text.hint" }}>
                End Date
              </Grid>
              <Grid item xs={8}>
                {formData.classTimeZone !== "" && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={formData.endDate}
                      minDate={minEndDate()}
                      sx={{ width: "100%" }}
                      onChange={handleEndDateChange}
                      timezone={formData.classTimeZone}
                      format="YYYY-MM-DD"
                    />
                  </LocalizationProvider>
                )}
              </Grid>
              <Grid item xs={4} sx={{ bgcolor: "text.hint" }}>
                Class Timezone
              </Grid>
              <Grid item xs={8}>
                <Autocomplete
                  value={formData.classTimeZone}
                  {...timezonesProps}
                  id="classTimeZone"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      required
                      sx={{ pb: 2 }}
                    />
                  )}
                  onChange={handleTimezoneChange}
                />
              </Grid>
              <Grid item xs={6} md={2} sx={{ bgcolor: "text.hint", px: 1 }}>
                <Button
                  onClick={handleEditClose}
                  variant="contained"
                  color="secondary"
                  sx={{ mb: 2 }}
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6} md={2} sx={{ bgcolor: "text.hint", px: 1 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ mb: 2 }}
                  fullWidth
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EditClass;
