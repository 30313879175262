import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { toTimeZoneSimple } from "../../../../services/date.service";

import { useSelector } from "react-redux";
import { SubmissionDetails } from "../../../../services/models/assignments.model";
import {
  getGradeByDigit,
  getGradeColor,
} from "../../../../services/grade.service";

interface GradeHistoryProps {
  open: boolean;
  onClose: () => void;
}

const GradeHistory: React.FC<GradeHistoryProps> = ({ open, onClose }) => {
  const submissionData: SubmissionDetails = useSelector(
    (state: any) => state.assignment.submissionData
  );
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          maxWidth: "unset",
          width: "60%",
          maxHeight: "80%",
          minHeight:"50%",
          margin: 0,
          padding: 0,
        },
      }}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
        <Button onClick={onClose} startIcon={<CloseIcon />}></Button>
        Score History
      </DialogTitle>
      <DialogContent dividers>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Effort</TableCell>
              <TableCell>Revision</TableCell>
              <TableCell>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {submissionData.scoresHistory.map((row: any, index: number) => (
              <TableRow key={`gh-${index}`}>
                <TableCell>{toTimeZoneSimple(row.timestamp)}</TableCell>
                <TableCell>
                  <Avatar
                    sx={{ bgcolor: getGradeColor(row.effort) }}
                    aria-label="recipe"
                  >
                    {getGradeByDigit(row.effort)}
                  </Avatar>
                </TableCell>
                <TableCell>
                  {" "}
                  <Avatar
                    sx={{ bgcolor: getGradeColor(row.revision) }}
                    aria-label="recipe"
                  >
                    {getGradeByDigit(row.revision)}
                  </Avatar>
                </TableCell>
                <TableCell>
                    {row.type}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

export default GradeHistory;
