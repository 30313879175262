import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { AnalyticsData, AnalyticsTabs } from "./model";

import ChangeDistGraph from "../../../education/ReadAssignment/Analytics/EssayMap";
import HeatMap from "../../../education/ReadAssignment/Analytics/HeatMap";
import WordChange from "../../../education/ReadAssignment/Analytics/WordChange";
import { getEssayMapData, getHeatMapData, getWordChangeData } from "./service";

interface AnalyticsProps {
  submissionId: string;
  open: boolean;
  handleClose: () => void;
  data: AnalyticsData;
  setAnalyticsData: (state: AnalyticsData) => void;
}

const Analytics: React.FC<AnalyticsProps> = (props) => {
  const { open, handleClose, data, setAnalyticsData, submissionId } = props;
  const { tab } = data;
  const handleChangeTab = (tab: AnalyticsTabs) =>
    setAnalyticsData({ ...data, tab });

  useEffect(() => {
    const updateData = async () => {
      switch (tab) {
        case "EssayMap":
          try {
            if (!data.essayMapData && open) {
              const response = await getEssayMapData(submissionId);
              if (response.status === 200) {
                setAnalyticsData({ ...data, essayMapData: response.data });
              }
            }
          } finally {
            break;
          }

        case "HeatMap":
          try {
            if (!data.heatMapData && open) {
              const response = await getHeatMapData(submissionId);
              if (response.status === 200) {
                setAnalyticsData({
                  ...data,
                  heatMapData: JSON.parse(response.data),
                });
              }
            }
          } finally {
            break;
          }
        case "WordChange":
          try {
            if (!data.wordChangeData && open) {
              const response = await getWordChangeData(submissionId);
              if (response.status === 200) {
                setAnalyticsData({
                  ...data,
                  wordChangeData: response.data,
                });
              }
            }
          } finally {
            break;
          }
      }
    };
    updateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, open]);

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Analytics
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}>
            {["EssayMap", "HeatMap", "WordChange"].map((page, index) => (
              // TODO name prop is the reason why we have to ts ignore
              // @ts-ignore
              <Button
                key={page}
                onClick={() => handleChangeTab(page as AnalyticsTabs)}
                sx={{
                  my: 2,
                  mx: 2,
                  color: "black",
                  display: "block",
                  textTransform: "none",
                }}
                name={index}
                variant="contained"
              >
                {page}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {data.tab === "EssayMap" && (
          <ChangeDistGraph message="Loading" data={data.essayMapData} />
        )}

        {/* HeatMap */}
        {data.tab === "HeatMap" && (
          <HeatMap message="Loading" data={data.heatMapData} />
        )}
        {data.tab === "WordChange" && (
          <WordChange data={data.wordChangeData} message="Loading" />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Analytics;
