import React from "react";
interface TotalTimeProps {
  totalTime: number;
}

const TotalTime: React.FC<TotalTimeProps> = ({ totalTime }) => {
  const days = Math.floor(totalTime / (24 * 3600));
  const hours = Math.floor((totalTime % (24 * 3600)) / 3600);
  const minutes = Math.floor((totalTime % 3600) / 60);
  const seconds = totalTime % 60;
  return (
    <div>
      {totalTime > 0 ? (
        <>
          {days > 0 && `${days}-Days`}
          {`${hours.toString().padStart(2, '0')}:`}
          {`${minutes.toString().padStart(2, '0')}:`}
          {`${seconds.toString().padStart(2, '0')}`}
        </>
      ) : (
        "-"
      )}
    </div>
  );
};

export default TotalTime;
