/* eslint-disable import/no-anonymous-default-export */ /* eslint-disable import/no-anonymous-default-export */
import { ActionType, SET_IS_LOADED, SET_IS_LOADING } from "./actions";
import { LoadingState, initialState } from "./model";

export default (state = initialState, action: ActionType): LoadingState => {
  const { type, payload } = action;

  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        loadingIds: [...state.loadingIds, payload.apiId],
      };
    case SET_IS_LOADED:
      return {
        ...state,
        loadingIds: state.loadingIds.filter((id) => id !== payload.apiId),
      };
    default:
      return state;
  }
};
