import { DeadlineSubmission } from "../../../../store/ReadAssignment/model";

export const showCommenting = (deadlineSubmissions: DeadlineSubmission[]) => {
  return deadlineSubmissions.some((c) => c.commentedFiles.length === 0);
};

export const isExcepted = (
  deadlineSubmissions: DeadlineSubmission[] | undefined,
  submission_status: string|undefined
): boolean => {
  if (deadlineSubmissions === undefined && submission_status===undefined) return false;
  let draftExp : boolean = false;
  let assignmentExp : boolean = false;
  if(deadlineSubmissions){
    const exception: number = deadlineSubmissions.filter(
      (c) => c.exception
    ).length;
    const exception_submission = deadlineSubmissions.filter((c) =>
      c.status.includes("EXCEPTION")
    ).length;
    draftExp = exception > exception_submission;
  }
  if(submission_status){
    assignmentExp = submission_status.includes("EXCEPTION");
  }



  
  return draftExp||assignmentExp;
};


