export const gradeStatus = {
  EvaluateFinalize: [
    "INITIAL",
    "REVISION",
    "EXCEPTION",
    "DRAFT_SUBMISSION",
    "INCOMPLETE",
  ],
  Reopen: [
    "FINAL_SUBMISSION",
    "AUTO_SUBMISSION",
    "INSTRUCTOR_SUBMISSION",
    "INCOMPLETE",
  ],
  Grade: ["FINAL_SUBMISSION", "AUTO_SUBMISSION", "INSTRUCTOR_SUBMISSION"],
};

export const tooltips = {
  EFFORT_TOOLTIP:
    "Effort is a measure of the amount of effort the student expended writing the assignment in terms of time, writing, and editing (including the types of edits). While the Effort score is meant to be advisory, the instructor may consider allocating up to 20 percent of the grade for the assignment to this metric.",
  REVISION_TOOLTIP:
    "Revision is a measure of how much deep revision the student engaged in for the assignment. While the Revision score is meant to be advisory, the instructor may consider allocating up to 20 percent of the grade for the assignment to this metric.",
  REWIND:"The Rewind Essay feature provides an animation showing the editing history of the document.",
  FEEDBACK:"The Feedback feature allows the instructor to provide feedback on the assignment at the various stages.",
  ANALYTICS:"The Analytics feature provides analytic graphs about the student's composition process.",
  SCORE:"The Score History feature shows the history of the Effort and Revision scores provided by the system on the various submissions.",
};

export const loading:string[] = ["getSubmissionData","endCommenting","draftSubmit","addNewFeedback","gradeSubmission","evaluateSubmission","TrackChangeAccept"];

export const TrackChangeMethods={ACTIVATE:"Activate", DEACTIVATE:"DeActivate", SHOW:"Show", FILENAME : "tr.py", ACCEPTALL:"AcceptAll", FINALIZE:"Finalize", apiId:"TrackChangeAccept"}
