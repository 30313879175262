import React from "react";
import ReactApexChart from "react-apexcharts";
import { getHeatMapLogs } from "../../services/classes.service";

class HeatmapGraph extends React.Component<any, any> {
  // TODO add interface
  constructor(props: any) {
    super(props);

    this.state = {
      series: null,
      options: {
        chart: {
          height: 600,
          type: "heatmap",
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#008FFB"],

        title: {
         text: "HeatMap",
        },
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.5,
            radius: 0,
            distributed: true,
            useFillColorAsStroke: true,
            colorScale: {
              ranges: [
                {
                  from: 0,
                  to: 40,
                  name: "low",
                  color: "#B9B9B9",
                },
                {
                  from: 40,
                  to: 100,
                  name: "medium",
                  color: "#128FD9",
                },
                {
                  from: 100,
                  to: 400,
                  name: "high",
                  color: "#3CD4A0",
                },
                {
                  from: 400,
                  to: 6000,
                  name: "extreme",
                  color: "#FF0000",
                },
              ],
            },
          },
        },
      },
    };
  }

  async componentDidMount() {
    const heatMapLogs = await getHeatMapLogs(this.props.subid);
    this.setState({ series: JSON.parse(heatMapLogs.data) });
  }

  render() {
    return (
      <>
        {this.state.series && (
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="heatmap"
            height={600}
          />
        )}
      </>
    );
  }
}

export default HeatmapGraph;
