/* eslint-disable import/no-anonymous-default-export */ /* eslint-disable import/no-anonymous-default-export */

import {
  ActionType,
  CLEAR_SNACKBAR_MESSAGE,
  SET_SNACKBAR_MESSAGE,
} from "./actions";
import { SnackbarMessageState, initialState } from "./model";

export default (
  state = initialState,
  action: ActionType
): SnackbarMessageState => {
  const { type, payload } = action;

  switch (type) {
    case SET_SNACKBAR_MESSAGE:
      return {
        ...state,
        message: {
          ...payload.message,
        },
      };
    case CLEAR_SNACKBAR_MESSAGE:
      return {
        ...state,
        message: {
          ...payload.message,
        },
      };

    default:
      return state;
  }
};
