import { Course, Review } from "../../../services/models/classes.model";

export interface DataProps {
  id: string;
  loading: boolean;
  myclass: Course | null;
  timeZone: string;
  modalBody: string;
  modalHeader: string;
  openModal: boolean;
  review:Review | null;
}

export const initComponentData: DataProps = {
  id: "",
  loading: false,
  myclass: null,
  timeZone: "",
  modalBody: "",
  modalHeader: "",
  openModal: false,
  review:null,
};


export interface ReviewDialogProps{
  open:boolean;
  review:Review | null;
  handleClose:()=>void;
  handleSubmit: (id:string) =>void;
}