import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import React from "react";

interface ArchiveNotificationProps {
  archiveId: string | null;
  handleClose: () => void;
  handleArchive: (id: string) => void;
}

const ArchiveNotification: React.FC<ArchiveNotificationProps> = ({
  archiveId,
  handleArchive,
  handleClose,
}) => {
  return (
    <Dialog
      open={archiveId !== null}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to archive this class?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>Disagree</Button>
        {archiveId !== null && (
          <Button onClick={() => handleArchive(archiveId)} autoFocus>
            Agree
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ArchiveNotification;
