import React, { useCallback, useId, useState } from "react";
import { useSelector } from "react-redux";
import {
  initPreview,
  PeerReviewDialogProps,
  PeerReviewItemViewModel,
  PeerReviewViewModel,
  Preview,
} from "./model";
import {
  DraftSubmission,
  Reviews,
  Student,
  Submission,
} from "../../../../store/ClassDetails/model";
import ReviewsRow from "./ReviewsRow";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import PreviewDialog from "./Preview";

const ReviewsTab: React.FC<PeerReviewDialogProps> = ({ assignmentId }) => {
  const students: Student[] = useSelector(
    (state: any) => state.classDetails.students
  );

  const [preview, setPreview] = useState<Preview>(initPreview);

  const handleClosePreview = () => {
    setPreview(initPreview);
  };

  const handleOpenPreview = (id: string) => {
    setPreview({ id: id, open: true });
  };

  const headerId = useId();

  const getMappedData = useCallback((): PeerReviewViewModel[] => {
    const result: PeerReviewViewModel[] = students.map((student: Student) => {
      var reviews: PeerReviewItemViewModel[] = [];

      student.submissions
        .filter(
          (submission: Submission) => submission.assignmentId === assignmentId
        )
        .forEach((e: Submission) => {
          e.draftSubmissions.forEach((ds: DraftSubmission) => {
            const pr: Reviews[] = ds.peerReviews;
            if (pr.length > 0) {
              reviews = [
                ...reviews,
                ...pr.map((tp: Reviews) => ({ ...tp, title: ds.title })),
              ];
            }
          });
        });

      return {
        reviewee: `${student.lastName}, ${student.firstName}`,
        revieweeId: student.id,
        pr: reviews,
      };
    });
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [students, assignmentId]);
  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow key={headerId}>
              <TableCell sx={{ width: 12 }} />
              <TableCell>Reviewee Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getMappedData().map((x) => (
              <ReviewsRow row={x} handleOpenPreview={handleOpenPreview} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PreviewDialog preview={preview} handleClose={handleClosePreview} />
    </>
  );
};

export default ReviewsTab;
