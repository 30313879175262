import dayjs, { Dayjs } from "dayjs";

import minMax from "dayjs/plugin/minMax";

dayjs.extend(minMax);

export const getHeatMapMinMaxDate = async (
  data: any[]
): Promise<number[] | undefined> => {
  // Search By name
  if (!data) return undefined;
  const dateObjects = data.map((item: any) => dayjs(item.name));

  // Find the min and max dates
  const minDate = dayjs.min(dateObjects);
  const maxDate = dayjs.max(dateObjects);
  if (minDate && maxDate) return [minDate?.valueOf(), maxDate?.valueOf()];
};

export const filterheatMap = (data: any[], minmax: number[]): any[] => {
  return data.filter((item: any) => {
    const current = dayjs(item.name).valueOf();
    return current >= minmax[0] && current <= minmax[1];
  });
};

export const getLineMinMaxDate = async (
  data: any[]
): Promise<number[] | undefined> => {
  // Search By name
  if (!data) return undefined;
  const dateObjects = data.map((item: any) => dayjs(item.id));

  // Find the min and max dates
  const minDate = dayjs.min(dateObjects);
  const maxDate = dayjs.max(dateObjects);
  if (minDate && maxDate) return [minDate?.valueOf(), maxDate?.valueOf()];
};

export const getTimeSeriesTotal = (data: any[]) => {
  const total = {};
  data.forEach((item) => {
    const data = item.data;
    if (data) {
      data.forEach((point) => {
        const { x, y } = point;
        if (total[x]) {
          total[x] += y;
        } else {
          total[point.x] = y;
        }
      });
    }
  });
  const result = Object.keys(total).map((key) => ({
    x: key,
    y: total[key],
  }));
  return [...data, { id: "TOTAL", data: result, color: "black" }];
};

export const filterlineGraph = (data: any[], minmax: number[]): any[] => {
  const response = data.filter((item: any) => {
    const current = dayjs(item.id).valueOf();
    return current >= minmax[0] && current <= minmax[1];
  });

  const total = {};
  response.forEach((item) => {
    const data = item.data;
    if (data) {
      data.forEach((point) => {
        const { x, y } = point;
        if (total[x]) {
          total[x] += y;
        } else {
          total[point.x] = y;
        }
      });
    }
  });
  const result = getTimeSeriesTotal(response);

  return result;
};

export const getTimeSeriesMinMax = async (
  data: any[]
): Promise<number[] | undefined> => {
  if (!data || data.length === 0) return undefined;

  // Convert 'x' field to dayjs objects
  // const dateObjects: Dayjs[] = data[0].data.map((item: any) => dayjs(item.x));
  const dateObjects: Dayjs[] = data.flatMap((x: any) =>
    x.data.map((item: any) => dayjs(item.x))
  );

  // Apply startOf and endOf to each date
  const minDate = dayjs.min(dateObjects.map((date) => date.startOf("day")));
  const maxDate = dayjs.max(dateObjects.map((date) => date.endOf("day")));

  // Return the min and max dates as timestamps, or undefined if not valid
  if (minDate && maxDate) {
    return [minDate.valueOf(), maxDate.valueOf()];
  }

  return undefined;
};

export const timeStringToSeconds = (time: any): number => {
  const [hours, minutes, seconds] = time.split(":").map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

// Async function to get the min and max of 'x'
export const getMinMaxX = (
  data: { x: string; y: number }[]
): { min: string | null; max: string | null } => {
  if (data.length === 0) {
    return { min: null, max: null }; // Handle empty array
  }

  let min = data[0].x;
  let max = data[0].x;

  // Loop through the array to find min and max based on 'x'
  data.forEach((item) => {
    const timeInSeconds = timeStringToSeconds(item.x);
    if (timeStringToSeconds(min) > timeInSeconds) {
      min = item.x;
    }
    if (timeStringToSeconds(max) < timeInSeconds) {
      max = item.x;
    }
  });

  return { min, max };
};

export const getYMinMax = (data: any): number[] | undefined => {
  const ys: number[] = data.flatMap((x: any) =>
    x.data.map((item: any) => item.y)
  );

  const min = Math.min(...ys);
  const max = Math.max(...ys);
  return [min, max];
};

export const getYAverage = (data: any[], series: string): number => {
  const selected = data.find((c) => c.id === series);

  // Ensure selected and selected.data are valid
  if (!selected || !selected.data || selected.data.length === 0) {
    return -1;
  }

  try {
    const d = selected.data;
    // Calculate the average of 'y' values
    return d.reduce((sum: number, c: any) => sum + c.y, 0) / d.length;
  } catch {
    return -1;
  }
};

export const filterTimeSeries = (data: any[], minmax: number[]) => {
  const result = data.map((d) => {
    return {
      id: d.id,
      data: d.data.filter((item: any) => {
        const current = dayjs(item.x).valueOf();
        return current >= minmax[0] && current <= minmax[1];
      }),
    };
  });
  return result;
};

export const extractSliderMarks = (
  minmax: number[],
  currentValue: number[]
): any[] => {
  const a = minmax.map((item) => {
    return { value: item, label: dayjs(item).format("YYYY-MM-DD") };
  });
  const b = currentValue.map((item) => {
    return { value: item, label: dayjs(item).format("YYYY-MM-DD") };
  });

  return a.concat(b);
};

export const secondToHDTime = (totalTime: number) => {
  const days = Math.floor(totalTime / (24 * 3600));
  const hours = Math.floor((totalTime % (24 * 3600)) / 3600);
  const minutes = Math.floor((totalTime % 3600) / 60);
  const seconds = totalTime % 60;
  let result = "";

  if (days > 0) {
    result += `${days}d `;
  }
  if (hours > 0) {
    result += `${hours}h `;
  }
  if (minutes > 0 || (!days && !hours)) {
    // Show minutes if no days/hours or there is a valid minute value
    result += `${minutes}m `;
  }
  result += `${seconds}s`; // Always show seconds

  return result.trim(); // Remove any trailing space
};

export const getDatafilterByTime = (data: any[], minmax: string[]) => {
  const min = timeStringToSeconds(minmax[0]);
  const max = timeStringToSeconds(minmax[1]);

  const result = data.map((d) => {
    return {
      id: d.id,
      data: d.data.filter((item: any) => {
        const current = timeStringToSeconds(item.x);
        return current >= min && current <= max;
      }),
    };
  });
  return result;
};

export const calculateStandardDeviationAndMean = (
  values: number[]
): { sd: number; mean: number } => {
  const mean = values.reduce((a, b) => a + b, 0) / values.length;

  const variance =
    values.reduce((a, b) => a + Math.pow(b - mean, 2), 0) / values.length;

  return { sd: Math.round(Math.sqrt(variance)), mean: Math.round(mean) };
};

export const formatTime = (value: number) => {
  value = Math.round(value);
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value % 3600) / 60);
  const seconds = value % 60;
  return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")} s`;
};
