export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_IS_LOADED = "SET_IS_LOADED";

export type ActionType = SetIsLoading | SetIsLoaded;

interface SetIsLoading {
  type: typeof SET_IS_LOADING;
  payload: {
    apiId: string;
  };
}
interface SetIsLoaded {
  type: typeof SET_IS_LOADED;
  payload: {
    apiId: string;
  };
}

export const setIsLoading = (apiId: string): SetIsLoading => ({
  type: SET_IS_LOADING,
  payload: {
    apiId,
  },
});

export const setIsLoaded = (apiId: string): SetIsLoaded => ({
  type: SET_IS_LOADED,
  payload: {
    apiId,
  },
});
