import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useTheme } from "@mui/material/styles";
import { ContactUsInfo, initiateContactUs } from "./model";
import { sendContactUs } from "./services";
import { useDispatch } from "react-redux";
import { setSnackbarMessage } from "../../store/UIActions/Snackbar/actions";
import { AlertTypes } from "../../store/UIActions/Snackbar/model";
import {
  setIsLoaded,
  setIsLoading,
} from "../../store/UIActions/Loading/actions";
import { API_IDS, MESSAGES } from "./constants";

const ContactUs = (props: any) => {
  const theme = useTheme();
  const radius = 5;
  const dispatch = useDispatch();
  const inputProps = {
    backgroundColor: theme.palette.background.default,
    borderTopRightRadius: radius,
    borderTopLeftRadius: radius,
    "& input:-webkit-autofill": {
      color: "red", // Change color for autofill text
      boxShadow: `0 0 0 100px ${theme.palette.background.default} inset`, // Override background color
    },
  };

  const labelProps = { sx: { pl: 1 } };

  const [state, setState] = useState<ContactUsInfo>(initiateContactUs);
  const handleChange = (e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const displayMessage = (message: string, type: AlertTypes) => {
    dispatch(
      setSnackbarMessage({
        type: type,
        showSnackBar: true,
        message: message,
      })
    );
  };

  const handleSubmit = async (e: any) => {
    try {
      e.preventDefault();
      dispatch(setIsLoading(API_IDS.CONTACT_US));
      const response = await sendContactUs(state);
      if (response.status === 200) {
        displayMessage(MESSAGES.CONTACT.SUCCESS, AlertTypes.SUCCESS);
        setState({ ...initiateContactUs });
      } else {
        throw new Error();
      }
    } catch (error: any) {
      displayMessage(MESSAGES.CONTACT.ERROR, AlertTypes.ERROR);
    } finally {
      dispatch(setIsLoaded(API_IDS.CONTACT_US));
    }
  };
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
      sx={{ py: 5, backgroundColor: "#F4FEFF" }}
      {...props}
    >
      <Grid
        item
        lg={4}
        md={6}
        sm={8}
        xs={10}
        direction="column"
        justifyContent="space-around"
        alignItems="center"
        spacing={3}
        sx={{ display: "flex" }}
      >
        <Card sx={{backgroundColor:"transparent", width:"80%", boxShadow:"0px 2px 1px -1px rgba(0,0,0,0.1)"}} >
          <CardHeader title={<Typography variant="h2" color="primary" sx={{ fontWeight: 'bold' }}>Contact Us</Typography>}  />
          <CardContent sx={{display: 'flex', alignItems: 'center'}} >
            <Avatar variant="square" sx={{ width: 72, height: 72, backgroundColor:theme.palette.primary.main }}>
              <AssignmentIcon  />
            </Avatar>
            <Stack spacing={0}>
            <Typography variant="body1" sx={{ m: 1 }} textAlign="left" >
              email
            </Typography>
            <Typography variant="body1" sx={{ m: 1,mt:0 }} textAlign="left" >
              info@antecedent.us
            </Typography>
            </Stack>
            
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        // lg={3}
        md={4}
        sm={8}
        xs={10}
        sx={{
          display: "flex",
          ml:5
        }}
        spacing={3}
        component="form"
        onSubmit={handleSubmit}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            p: 3,
            borderRadius: radius,
            width: "100%",
          }}
        >
          <Stack>
            <TextField
              required
              id="name"
              name="name"
              label="Name"
              type="text"
              fullWidth
              variant="standard"
              margin="normal"
              sx={inputProps}
              InputLabelProps={labelProps}
              onChange={handleChange}
              value={state.name}
            />
            <TextField
              required
              id="email"
              name="email"
              label="Email"
              type="email"
              fullWidth
              variant="standard"
              margin="normal"
              sx={inputProps}
              InputLabelProps={labelProps}
              onChange={handleChange}
              value={state.email}
            />
            <TextareaAutosize
              minRows={7}
              aria-label="maximum height"
              name="message"
              id="message"
              placeholder="Message"
              onChange={handleChange}
              defaultValue={state.message}
              value={state.message}
              required
              className="textareaWhite"
            />
            <Button
              sx={{
                backgroundColor: theme.palette.background.default,
                "&:hover": {
                  bgcolor: theme.palette.background.paper, // Change this to the desired color
                },
              }}
              type="submit"
            >
              Submit
            </Button>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ContactUs;
