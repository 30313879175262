import { DATA_LOADING_SUCCESS, DATA_LOADING_FAIL, SET_MESSAGE } from "./types";
import * as DataService from "../services/data.service";

export const getClassDetailsIns = (id) => (dispatch) => {
  return DataService.getClassDetailsIns(id).then(
    (response) => {
      dispatch({
        type: DATA_LOADING_SUCCESS,
        payload: { data: response.data },
      });
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DATA_LOADING_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
    }
  );
};

export const addNewAssignment =
  (id, title, pointValue, startDate, endDate, postDate, description) =>
  (dispatch) => {
    return DataService.addNewAssignment(
      id,
      title,
      pointValue,
      startDate,
      endDate,
      postDate,
      description
    ).then(
      (response) => {
        dispatch({
          type: DATA_LOADING_SUCCESS,
          payload: { data: response.data },
        });
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: DATA_LOADING_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
      }
    );
  };

export const getSubmissionTextLogs = (id) => (dispatch) => {
  return DataService.getSubmissionTextLogs(id).then(
    (response) => {
      dispatch({
        type: DATA_LOADING_SUCCESS,
        payload: { data: response.data },
      });
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DATA_LOADING_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
    }
  );
};

export const getSubmissionData = (id) => (dispatch) =>{
  return DataService.getSubmissionData(id).then(
    (response) => {
      dispatch({
        type: DATA_LOADING_SUCCESS,
        payload: { data: response.data },
      });
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DATA_LOADING_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
    }
  );
};
