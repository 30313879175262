import React from "react";
import { Slide } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  // TODO look at this later
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

export default Transition;
