export interface Tracker{
    id: number;
    name: string;
}
interface Assignee{
    id: number;
    name: string;
}

export interface SupportProject{
    id:number;
    status: number;
    default_assignee:Assignee;
    trackers:Tracker[];
}

export interface SupportRequest{
    option:number;
    name:string;
    email:string;
    message:string;
    currentURL:string;
}

export const initSupportRequest = {
    option:5,
    name:"",
    email:"",
    message:"",
    currentURL:window.location.href,
}




