export interface SearchRequest{
    firstName:string;
    lastName:string;
    username:string;
    email:string;
    phone:string;
    pending:boolean;
}

export const SearchRequestInit:SearchRequest = {
    firstName:"",
    lastName:"",
    username:"",
    email:"",
    phone:"",
    pending:false,
}

export interface Role{
    id:string;
    name:string;
    selected:boolean;
}

export interface User{
    id:string;
    firstName:string;
    lastName:string;
    username:string;
    email:string;
    phone:string;
    roles:Role[];
    profile:string[];
    confirmed:boolean;
}

export const userInitState:User = {
    id:"",
    firstName:"",
    lastName:"",
    username:"",
    email:"",
    phone:"",
    roles:[],
    profile:[],
    confirmed:false,
}


// Page properties interfaces

export interface UserSearchProps{
    searchList:User[];
    setSearchList: (response:User[])=>void;
    handleNew:()=>void;
    
}

export interface UserSearchListProps{
    searchList:User[];
    setSelectedUser :(id:string)=>void;
}


export interface UserModifyProps{
    user:User;
    open:boolean;
    handleClose:()=>void;
    handleUpdateResult:(user:User)=>void;
}