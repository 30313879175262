import RewindActionTypes from "./constants";
import { Rewind } from "./model";

export const setCurrent = (current: number) => ({
  type: RewindActionTypes.SET_CURRENT,
  payload: current,
});

export const setIsPlaying = (isPlaying: boolean) => ({
  type: RewindActionTypes.SET_IS_PLAYING,
  payload: isPlaying,
});

export const setSleepTime = (sleepTime: number) => ({
  type: RewindActionTypes.SET_SLEEP_TIME,
  payload: sleepTime,
});

export const incCurrent = (speed: number) => ({
  type: RewindActionTypes.SET_CURRENT,
  payload: speed, // Increment the current state by 1
  operation: 'inc',
});

export const decCurrent = () => ({
  type: RewindActionTypes.SET_CURRENT,
  payload: 1, // Decrement the current state by 1
  operation: 'dec',
});

export const reset = () => ({type: RewindActionTypes.SET_INIT})

export const setToState = (lastState:Rewind) => ({type:RewindActionTypes.SET_TO_STATE, payload:lastState})
