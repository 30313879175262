export const MENUS = {
  options: [
    { caption: "Home", link: "home" },
    { caption: "About Us", link: "aboutus" },
    { caption: "FEATURES", link: "feature" },
    { caption: "EVENTS", link: "event" },
    { caption: "Contact Us", link: "contactus" },
  ],
  accounts: [
    { caption: "Sign in", link: "/login", type: "o" },
    { caption: "Free Sign Up", link: "/register", type: "c" },
  ],
};
