import React from "react";

import {
  Container,
  Typography,
  Grid,
  CssBaseline,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import AboutUs from "./aboutus";
import ContactUs from "./contactus";
import Events from "./events";
import { connect } from "react-redux";
import { withRouter } from "../../common/withRouter";
import Account from "./account";
import Footer from "./footer";
import { SectionIds } from "./constants";
import Features from "./features";
import Partners from "./partners";

import { Link } from "react-scroll";

class Home extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.isLoading = this.isLoading.bind(this);
  }

  isLoading() {
    try {
      return this.props.loader.loadingIds.length > 0;
    } catch {
      return false;
    }
  }
  render() {
    return (
      <>
        <Grid
          container
          component="main"
          sx={{ height: "calc(100vh-71px)" }}
          id="home"
        >
          <CssBaseline />
          <Grid
            item
            xl={12}
            xs={12}
            sx={{
              backgroundImage:
                "url(" + process.env.PUBLIC_URL + "/hero-bg.jpg)",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            id={SectionIds.TOP}
          >
            <div className="dark-overlay">
              <Container
                fixed
                maxWidth="lg"
                sx={{
                  color: "white",
                  position: "relative",
                  top: "30%",
                  transform: "translateY(-50%)",
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "bolder",
                    fontSize: "4rem",
                  }}
                  className="fade-in"
                >
                  The Humanity
                </Typography>
                <Typography
                  variant="h1"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "bolder",
                    fontSize: "4rem",
                  }}
                  className="fade-in"
                >
                  is in the Process.
                </Typography>
              </Container>
              {/* An integrity-enhanced word processor and learning management system.  */}
              <Container
                fixed
                maxWidth="lg"
                sx={{
                  color: "white",
                  position: "relative",
                  top: "40%",
                  transform: "translateY(-50%)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="fade-in"
                >
                  An integrity-enhanced word processor and learning management
                  system.
                </Typography>
                <Button variant="contained" size="large" sx={{ mt: 4 }}>
                  <Link
                    to="aboutus"
                    smooth={true}
                    duration={500}
                    offset={-100}
                    spy={true}
                  >
                    learn More
                  </Link>
                </Button>
              </Container>
            </div>
          </Grid>
        </Grid>
        <AboutUs id={SectionIds.ABOUTUS} />
        <Features id={SectionIds.FEATURE} />
        <Events id={SectionIds.EVENT} />
        <ContactUs id={SectionIds.CONTACTUS} />
        <Partners />
        <Account />
        <Footer />

        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={this.isLoading()}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const { loader } = state;
  return {
    loader,
  };
}

export default withRouter(connect(mapStateToProps)(Home));
