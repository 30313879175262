import { AlertColor } from "@mui/material";

export enum AlertTypes {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success",
}

export const initialState: SnackbarMessageState = {
  message: {
    type: "",
    message: "",
    showSnackBar: false,
  },
};
export interface SnackbarMessageState {
  message: SnackbarMessage;
}
export interface SnackbarMessage {
  type: AlertColor | "";
  message: string;
  showSnackBar: boolean;
}
