import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import authHeader from "./auth-header";
import {
  ClassDetails,
  CreateAssignmentBody,
  UpdateClassDetails,
} from "../store/ClassDetails/model";

const HOST = process.env.REACT_APP_API_URL;
const CLASSES = "api/classes";
const GET_CLASS = "getclass";
const ASSIGNMENT = "assignment";
const DROP_STUDENT = "dropstudent";
const CREATE_ASSIGNMENT = "createAssignment";
const STOPED = "stoped";
const VISIBLE = "visible";
const UPDATE_CLASS = "updateClass";

export const getClassDetails = async (classId: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${GET_CLASS}`,
    params: { id: classId },
    data: {},
  };
  try {
    const apiCall: AxiosResponse<ClassDetails> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const updateClassDetails = async (updateDetails: UpdateClassDetails) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${UPDATE_CLASS}`,
    params: {},
    data: { ...updateDetails },
  };
  try {
    const apiCall: AxiosResponse<boolean> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const deleteAssignment = async (assignmentId: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "DELETE",
    url: `${HOST}/${CLASSES}/${ASSIGNMENT}`,
    params: { id: assignmentId },
    data: {},
  };
  try {
    const apiCall: AxiosResponse<ClassDetails> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const visibleAssignment = async (
  assignmentId: string,
  status: boolean
) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${ASSIGNMENT}/${VISIBLE}`,
    params: { id: assignmentId, status },
    data: {},
  };

  try {
    const apiCall: AxiosResponse<ClassDetails> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const stopAssignment = async (assignmentId: string, status: boolean) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${ASSIGNMENT}/${STOPED}`,
    params: { id: assignmentId, status },
    data: {},
  };
  try {
    const apiCall: AxiosResponse<ClassDetails> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const removeStudent = async (classId: string, studentId: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "DELETE",
    url: `${HOST}/${CLASSES}/${DROP_STUDENT}`,
    data: {
      classId,
      studentId,
    },
  };
  try {
    const apiCall: AxiosResponse<Boolean> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const createAssignment = async (
  assignmentBody: CreateAssignmentBody
) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${CREATE_ASSIGNMENT}`,
    data: { ...assignmentBody },
  };
  try {
    const apiCall: AxiosResponse<ClassDetails> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const updateAssignment = async (
  assignmentBody: CreateAssignmentBody
) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "PATCH",
    url: `${HOST}/${CLASSES}/${ASSIGNMENT}`,
    data: { ...assignmentBody },
  };
  try {
    const apiCall: AxiosResponse<ClassDetails> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};
