import {
  AccountCircle as AccountCircleIcon,
  VerifiedUser as VerifiedUserIcon,
} from "@mui/icons-material/";
import { MenuGroupProps } from "./model";
import RttIcon from '@mui/icons-material/Rtt';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

export const menus: MenuGroupProps[] = [
  {
    groupType: "USER",
    groupLabel: "User Management",
    access: "ROLE_ADMIN",
    options: [
      {
        label: "Manage Users",
        link: "/admin/user",
        icon: <AccountCircleIcon />,
      },
      {
        label: "Verify Users",
        link: "/admin/VerifyUser",
        icon: <VerifiedUserIcon />,
      },
    ],
  },

  {
    groupType: "Submission",
    groupLabel: "Submission Management",
    access: "ROLE_ADMIN",
    options: [
      {
        label: "Fix/Format Text",
        link: "/admin/fixtext",
        icon: <RttIcon />,
      },
      {
        label: "Search Submissions",
        link: "/admin/searchsubmission",
        icon: <ContentPasteSearchIcon />,
      },
     
    ],
  },
];
