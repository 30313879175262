export const SET_SUPPORT_CLOSE = "SET_SUPPORT_CLOSE";
export const SET_SUPPORT_OPEN = "SET_SUPPORT_OPEN";

export type ActionType = SetSupportClose | SetSupportOpen;

interface SetSupportClose {
  type: typeof SET_SUPPORT_CLOSE;
}
interface SetSupportOpen {
  type: typeof SET_SUPPORT_OPEN;
}

export const setSupportOpen = (): SetSupportOpen => ({
  type: SET_SUPPORT_OPEN,
});

export const setSupportClose = (): SetSupportClose => ({
  type: SET_SUPPORT_CLOSE,
});
