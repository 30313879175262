import React from "react";
import { connect } from "react-redux";
import Main from "../../elements/public/main";
import { logout } from "../../actions/auth";

class Logout extends React.Component<any, any> {
  constructor(props: any) {
    // TODO add interface
    super(props);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }
  componentDidMount() {
    this.props.dispatch(logout());
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
    window.location.replace("/");
  }
  render() {
    return (
      <Main>
        <h1>logged out successfully</h1>
      </Main>
    );
  }
}

function mapStateToProps(state: any) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(Logout);
