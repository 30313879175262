import React, { useEffect, useState } from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InstCollab from "../../../../elements/public/Instructor.FS.Editor";

interface FullScreenProps {
  fsOpen: boolean;
  handleFsClose: () => void;
  target: string;
}

const FullScreen: React.FC<FullScreenProps> = (props) => {
  const [display, setDisplay] = useState<boolean>(false);
  useEffect(() => {
    if (props.fsOpen && props.target === "collabora-online-viewer-fs") {
      setDisplay(true);
    }
  }, [props]);

  const handleClose = () =>{
    setDisplay(false);
    props.handleFsClose();
  }

  return (
    <Dialog
      fullScreen
      open={props.fsOpen}
    //   TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Full Screen Panel
          </Typography>
        </Toolbar>
      </AppBar>
      <Container
        maxWidth={false}
        sx={{
          contain: "layout",
          height: ["inherit ", "-webkit-fill-available"],
          width: "95vw",
        }}
      >
        {display && <InstCollab />}
      </Container>
    </Dialog>
  );
};

export default FullScreen;
