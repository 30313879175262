import { ResponsiveLine } from "@nivo/line";
import React from "react";

interface LineGraphProps {
  data: any | null;
}
const LineGraph: React.FC<LineGraphProps> = ({ data }) => {
  const DashedSolidLine = ({ series, lineGenerator, xScale, yScale }) => {
    return series.map(({ id, data, color }, index) => (
      <path
        key={id}
        d={lineGenerator(
          data.map((d) => ({
            x: xScale(d.data.x),
            y: yScale(d.data.y),
          }))
        )}
        fill="none"
        stroke={color}
        style={
          id === "TOTAL"
            ? {
                // simulate line will dash stroke when index is even
                strokeDasharray: "5, 8",
                strokeWidth: 2,
              }
            : {
                // simulate line with solid stroke
                strokeWidth: 1,
              }
        }
      />
    ));
  };
  return data ? (
    <ResponsiveLine
      data={data}
      margin={{ top: 50, right: 110, bottom: 50, left: 80 }}
      xScale={{ type: "point" }}
      yScale={{ type: "linear", min: 0, max: "auto" }}
      // yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      colors={{ scheme: "category10" }}
      // pointSize={14}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Hour of the Day",
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        legend: "Words Changed",
        legendPosition: "middle",
        legendOffset: -60,
      }}
      legends={[
        {
          anchor: "top-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      curve="monotoneX"
      layers={[
        // includes all default layers
        "grid",
        "markers",
        "axes",
        "areas",
        "crosshair",
        "slices",
        "points",
        "mesh",
        "legends",
        DashedSolidLine, // add the custome layer here
      ]}
      enableSlices={"x"}
      // tooltip={(props: any) => {
      //   return (
      //     <div
      //       style={{
      //         background: "white",
      //         padding: "10px",
      //         border: "1px solid #ccc",
      //       }}
      //     >
      //       {props?.slice.points[0].data.y}
      //     </div>
      //   );
      // }}
    />
  ) : (
    <>Loading...</>
  );
};

export default LineGraph;
