import { formatDuration } from "date-fns";
import React, { useEffect, useState } from "react";
import { Metrics } from "../../../services/models/classes.model";
import * as DateService from "../../../services/date.service";
import {
  styled,
  CardContent,
  Skeleton,
  Avatar,
  IconButton,
  Card,
  CardHeader,
  Collapse,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { LastSubmissionDate } from "./styled-components";

interface TimeMetricsProps {
  active: Metrics;
  think: Metrics;
  real: Metrics;
  status: string;
  lastEvaluationDate: string;
  lastSubmissionDate: string;
  title: string;
  isLoading?: boolean;
  isLateSubmission?: boolean;
  classRank: number;
  classNumMembers: number;
}

const ExpandMore = styled((props: any) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function TimeMetrics(props: TimeMetricsProps) {
  const [expanded, setExpanded] = useState(false);
  const [rushRatioStyle, setRushRatioStyle] = useState({
    color: "",
  });

  const activeInSecond =
    props.active?.d * 86400 +
    props.active?.h * 3600 +
    props.active?.m * 60 +
    props.active?.s;
  const thinkInSecond =
    props.think?.d * 86400 +
    props.think?.h * 3600 +
    props.think?.m * 60 +
    props.think?.s;
  const totalInSecond =
    props.real?.d * 86400 +
    props.real?.h * 3600 +
    props.real?.m * 60 +
    props.real?.s;

  const rushRatio = Number(activeInSecond / thinkInSecond).toFixed(1);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (Number(rushRatio) < 0.7) {
      setRushRatioStyle({ color: "green" });
    } else if (Number(rushRatio) >= 0.7 && Number(rushRatio) < 0.9) {
      setRushRatioStyle({ color: "orange" });
    } else {
      setRushRatioStyle({ color: "red" });
    }
  }, [rushRatio]);

  return (
    <Card sx={{ mt: 1 }}>
      <CardHeader
        sx={{ height: 50, mt: 0 }}
        avatar={
          <Avatar sx={{ bgcolor: "#5b3283" }}>
            <AccessTimeIcon />
          </Avatar>
        }
        action={
          <ExpandMore
            sx={{ pt: 0 }}
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
        title={props.title}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {!props.isLoading ? (
            <>
              <Tooltip
                arrow
                disableFocusListener
                disableTouchListener
                title="Active Writing Time is the sum of all the time that the student was consistently writing while editing the document. Quality original writing involves pauses where the writer thinks about their language choices. 
                Accordingly, if Active Writing Time approaches or exceeds Think/Pause Time, then this suggests that the student may have typed out previously written language - or wrote the piece in a rush with minimal care. 
                Quality original writing should have substantial Active Writing Time but longer Think/Pause Time."
                placement="right-start"
              >
                <p>
                  <b>Active writing time:</b>{" "}
                  {activeInSecond >= 60
                    ? formatDuration(
                        {
                          days: props.active?.d,
                          hours: props.active?.h,
                          minutes: props.active?.m,
                        },
                        {
                          delimiter: ", ",
                        }
                      )
                    : "< 1 minute"}
                </p>
              </Tooltip>
              <Tooltip
                arrow
                disableFocusListener
                disableTouchListener
                title="Think/Pause Time is the sum of all the short to medium-length pauses during the writing process. 
                Think/Pause Time is understood as including both moments of cognitive effort while writing and
                 short moments of distraction or focusing on other tasks. Long gaps are considered breaks and
                  not included in Think/Pause Time."
                placement="right-start"
              >
                <p>
                  <b>Think/pause time:</b>{" "}
                  {thinkInSecond >= 60
                    ? formatDuration(
                        {
                          days: props.think?.d,
                          hours: props.think?.h,
                          minutes: props.think?.m,
                        },
                        {
                          delimiter: ", ",
                        }
                      )
                    : "< 1 minute"}
                </p>
              </Tooltip>
              <Tooltip
                arrow
                disableFocusListener
                disableTouchListener
                title="Total Writing Time is the sum of Think/Pause Time and Active Writing Time, plus all breaks in writing 
                reduced to medium-length pauses. In general, the longer the Total Writing Time, the 
                more effort the student expended on the assignment. Very short writing times may be understood as a red flag."
                placement="right-start"
              >
                <p>
                  <b>Total writing time:</b>{" "}
                  {totalInSecond >= 60
                    ? formatDuration(
                        {
                          days: props.real?.d,
                          hours: props.real?.h,
                          minutes: props.real?.m,
                        },
                        {
                          delimiter: ", ",
                        }
                      )
                    : "< 1 minute"}{" "}
                </p>
              </Tooltip>
              {props.classRank > 0 && props.classNumMembers > 0 && (
                <Tooltip
                  arrow
                  disableFocusListener
                  disableTouchListener
                  title="Rank is the student's current ranking in the class for Total Writing Time for this assignment."
                  placement="right-start"
                >
                  <p>
                    <b>Rank:</b>
                    {` ${props.classRank}/${props.classNumMembers}`}
                  </p>
                </Tooltip>
              )}
              <Tooltip
                arrow
                disableFocusListener
                disableTouchListener
                title="Rush Ratio is the ratio of Active Writing Time to Think/Pause Time expressed as Active Writing Time divided by
                Think/Pause Time. Writing that is quickly typed out -- indicating that the student either typed out previously
                 written language or wrote the essay in a rush -- will generally have a Rush Ratio greater than or equal to 0.9."
                placement="right-start"
              >
                <p>
                  <b>Rush ratio:</b>{" "}
                  <span style={rushRatioStyle}>
                    {!Number.isNaN(rushRatio) ? rushRatio : "Not available"}
                  </span>
                </p>
              </Tooltip>
              <Tooltip
                arrow
                disableFocusListener
                disableTouchListener
                title="Last Evaluation Date is the last time the Effort and Revision scores were updated by the system. 
                Any editing the student has done since the Last Evaluation Date is not reflected in 
                the Effort and Revision scores."
                placement="right-start"
              >
                <p>
                  <b>Last evaluation date:</b>{" "}
                  {props.lastEvaluationDate
                    ? DateService.toTimeZone(props.lastEvaluationDate)
                    : ""}
                </p>
              </Tooltip>
              <p>
                <b>Last submission date:</b>{" "}
                <LastSubmissionDate $isLate={props.isLateSubmission}>
                  {props.lastSubmissionDate
                    ? DateService.toTimeZone(props.lastSubmissionDate)
                    : ""}
                </LastSubmissionDate>
              </p>
            </>
          ) : (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
}
export default TimeMetrics;
