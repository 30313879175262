import React, { useEffect, useRef, useState } from "react";
import { SearchRequest, SearchRequestInit, UserSearchProps } from "./model";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { SearchUserAction, allValuesEmpty } from "./services";
import { useDispatch } from "react-redux";
import { validate } from "../../../../actions/auth";

const UserSearch: React.FC<UserSearchProps> = (props) => {
  const submitRef = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  // @ts-ignore
  dispatch(validate());
  const [formdata, setFormData] = useState<SearchRequest>(
    !window.location.pathname.endsWith("VerifyUser")
      ? SearchRequestInit
      : { ...SearchRequestInit, pending: true }
  );

  const handleChange = (e: any) => {
    if (e.target.type === "checkbox") {
      setFormData({ ...formdata, [e.target.name]: e.target.checked });
      return;
    }
    setFormData({ ...formdata, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (window.location.pathname.endsWith("VerifyUser")) {
      setTimeout(() => {
        if (submitRef.current) {
          submitRef.current.click();
        }
      }, 500); // Adjust the delay as necessary
    }
  }, []);

  const handleSearch = async (e: any) => {
    e.preventDefault();
    const response = await SearchUserAction(formdata);
    props.setSearchList(response.data);
  };

  return (
    <Grid item xs={12}>
      <Paper sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <Box
          sx={{
            my: 0,
            // mx: 4,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{ m: 1, bgcolor: "secondary.main", width: 32, height: 32 }}
          >
            <ManageSearchIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            User Search
          </Typography>
        </Box>
        <Divider />
        <Grid
          container
          spacing={2}
          component="form"
          onSubmit={handleSearch}
          name="searchStudent"
          id="searchStudent"
          
        >
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              required={allValuesEmpty(formdata)}
              id="firstName"
              name="firstName"
              label="First name"
              fullWidth
              variant="standard"
              margin="normal"
              onChange={handleChange}
              value={formdata.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              required={allValuesEmpty(formdata)}
              id="lastName"
              name="lastName"
              label="Last name"
              fullWidth
              variant="standard"
              margin="normal"
              onChange={handleChange}
              value={formdata.lastName}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              required={allValuesEmpty(formdata)}
              id="username"
              name="username"
              label="Username"
              fullWidth
              variant="standard"
              margin="normal"
              onChange={handleChange}
              value={formdata.username}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              required={allValuesEmpty(formdata)}
              id="email"
              name="email"
              label="Email"
              fullWidth
              variant="standard"
              margin="normal"
              onChange={handleChange}
              value={formdata.email}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              required={allValuesEmpty(formdata)}
              id="phone"
              name="phone"
              label="Phone"
              fullWidth
              variant="standard"
              margin="normal"
              onChange={handleChange}
              value={formdata.phone}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            sx={{ display: "flex", alignItems: "flex-end" }}
          >
            <FormControlLabel
              // sx={{ ml: 0 }}
              control={
                <Checkbox
                  color="primary"
                  name="pending"
                  id="pending"
                  onChange={handleChange}
                  checked={formdata.pending}
                />
              }
              label="Pending? "
              labelPlacement="start"
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            sx={{ display: "flex", alignItems: "flex-end" }}
          >
            <ButtonGroup
              variant="outlined"
              aria-label="Loading button group"
              fullWidth
            >
              <Button type="submit" variant="contained" fullWidth ref={submitRef}>
                Search
              </Button>
              <Button variant="contained" fullWidth onClick={props.handleNew} >
                Add New
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default UserSearch;
