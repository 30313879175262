import React, { useEffect, useState } from "react";
import { FeedBack } from "../../../../services/models/assignments.model";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Paper,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { toTimeZone } from "../../../../services/date.service";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

interface FeedbackProps {
  feedback: FeedBack;
  itemKey: number;
  disabled: boolean;
  setDisabled: (disabled: boolean) => void;
  handleDelete: (index: number) => void;
  handleUpdate: (index: number, comment: string) => void;
}

const FeedbackItem: React.FC<FeedbackProps> = ({
  feedback,
  itemKey,
  disabled,
  handleDelete,
  handleUpdate,
  setDisabled,
}) => {
  const { comment, timestamp, read } = feedback;
  const [showEditing, setShowEditing] = useState<boolean>(false);
  const [commentText, setCommentText] = useState<string>(comment);

  const handleEditClick = () => {
    setShowEditing(!showEditing);
    setDisabled(true);
  };

  useEffect(() => {
    setCommentText(comment);
  }, [comment]);

  return (
    <Box
      sx={{ position: "relative" }} // Ensure position is relative for absolute positioning of action buttons
    >
      {!showEditing && (
        <>
          <Paper
            elevation={2}
            sx={{
              m: 1,
              mb: "2px",
              minHeight: "50px",
              display: "flex",
              alignItems: "center",
              p: 1,
              backgroundColor: "#29575e2b",
            }}
          >
            <>
              {comment.split("\n").map((par: string, index: number) => (
                <React.Fragment key={index}>
                  {par}
                  <br />
                </React.Fragment>
              ))}
            </>
          </Paper>
          <Paper elevation={0} sx={{ textAlign: "right" }}>
            <Typography
              variant="caption"
              align="right"
              sx={{ color: read ? "#29575e" : "#9d0000", mr: 2, mt: 0 }}
            >
              {toTimeZone(timestamp)}
            </Typography>
          </Paper>
          {/* Show action buttons */}

          <Box
            sx={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              right: 10,
              display: "flex",
            }}
          >
            <ButtonGroup
              color="primary"
              disabled={disabled}
              orientation="horizontal"
              size="small"
              variant="text"
            >
              <IconButton
                onClick={() => handleDelete(itemKey)}
                color="primary"
                disabled={disabled}
                size="small"
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                onClick={handleEditClick}
                color="primary"
                disabled={disabled}
              >
                <EditIcon />
              </IconButton>
            </ButtonGroup>
          </Box>
        </>
      )}
      {showEditing && (
        <>
          <TextareaAutosize
            minRows={4}
            placeholder="Feedback"
            onChange={(event) => setCommentText(event.target.value)}
            value={commentText}
          ></TextareaAutosize>
          <Box
            sx={{
              position: "absolute",
              top: "30%",
              transform: "translateY(-50%)",
              right: 40,
              display: "flex",
              gap: "1px",
            }}
          >
            <ButtonGroup
              color="primary"
              disabled={false}
              orientation="horizontal"
              size="small"
              variant="text"
            >
              <Button
                onClick={() => {
                  // console.log(commentText);
                  setShowEditing(false);
                  setCommentText(comment);
                  setDisabled(false);
                }}
                // onClick={handleSend}
                // disabled={!enableSend}
                // startIcon={<SendIcon />}
                sx={{ alignSelf: "flex-end" }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleUpdate(itemKey, commentText);
                  setShowEditing(false);
                  setDisabled(false);
                }}
                // onClick={handleSend}
                // disabled={!enableSend}
                // startIcon={<SendIcon />}
                sx={{ alignSelf: "flex-end" }}
              >
                Update
              </Button>
            </ButtonGroup>
          </Box>
        </>
      )}
    </Box>
  );
};

export default FeedbackItem;
