import React, { useEffect } from "react";
import { PreviewDialogProps } from "./model";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import authToken from "../../../../services/auth-token";

const PreviewDialog: React.FC<PreviewDialogProps> = ({
  handleClose,
  preview,
}) => {
  const EDITOR_URL = process.env.REACT_APP_INS_EDITOR_URL;

  useEffect(() => {
    if (preview.id !== "") {
      setTimeout(() => {
        const formformExists: any = document.getElementById(
          "collabora-submit-form"
        );

        if (formformExists) {
          formformExists.submit();
        }
      }, 500);
    }
  }, [preview, EDITOR_URL]);
  return (
    <Dialog
      open={preview.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
      fullWidth
    >
      <DialogContent sx={{ height: "85vh" }}>
        {EDITOR_URL !== "" && preview.id !== "" && (
          <>
            <form
              action={EDITOR_URL + preview.id}
              encType="multipart/form-data"
              method="post"
              target="collabora-online-viewer"
              id="collabora-submit-form"
            >
              <input
                name="access_token"
                value={authToken()}
                type="hidden"
                id="access-token"
              />
              <input
                name="css_variables"
                value="--color-main-text=#000;--color-text-dark=#000;--color-text-darker=#000;--color-text-lighter=#000;--color-main-background=#fff;--color-background-dark=#fff;--color-background-darker=#fff;--color-background-lighter=#fff;"
                type="hidden"
              />

              <input
                name="ui_defaults"
                value="UIMode=tabbed;TextRuler=false;TextSidebar=false;"
                type="hidden"
              />
            </form>
            <Container
              maxWidth={false}
              sx={{
                contain: "layout",
                height: ["inherit ", "-webkit-fill-available"],
              }}
            >
              <iframe
                title="Collabora Online Viewer"
                id="collabora-online-viewer"
                name="collabora-online-viewer"
                allowFullScreen={true}
              ></iframe>
            </Container>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewDialog;
