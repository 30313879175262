import React from "react";
import { Button, Grid, Link, Stack, Typography } from "@mui/material";
import { EventDetails } from "./constants";

const Events = (props: any) => {
  // const MainColor = theme.palette.primary | primary
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
      sx={{ my: 0, pb: 5 }}
      {...props}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          my: 5,
          pt: "0px!important",
        }}
      >
        <Typography variant="h2" color="primary" sx={{ fontWeight: "bold" }}>
          Events
        </Typography>
      </Grid>
      {EventDetails.map((item: any) => (
        <>
          <Grid
            item
            lg={4}
            md={6}
            sm={8}
            xs={10}
            direction="column"
            justifyContent="space-around"
            alignItems="center"
            spacing={3}
            sx={{
              display: "flex",
              backgroundImage: `url('${item.media}')`,
              boxShadow: "0px 0px 10px 5px rgba(0, 0, 0, 0.5)",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "contain", // Make sure the entire image is visible
              backgroundPosition: "center",
              ml: 10,
              width: "100%", // Ensure it covers the entire width of the grid
              height: "auto", // Adjust height automatically to maintain aspect ratio
              aspectRatio: "16/9", // Set this to the aspect ratio of your image (example is 16:9)
            }}
          />

          <Grid
            item
            // lg={3}
            md={4}
            sm={8}
            xs={10}
            sx={{
              display: "flex",
              ml: 5,
            }}
            spacing={3}
          >
            <Stack>
              <Typography variant="h2" sx={{ fontWeight: "bold" }}>
                {item.title}
              </Typography>
              <ol>
                {item.desc.map((ls: string) => (
                  <li>
                    <Typography variant="body1" sx={{ m: 2 }}>
                      {ls}
                    </Typography>
                  </li>
                ))}
              </ol>
              <Button
                component={Link}
                href={item.link}
                variant="text"
                target="_blank"
              >
                Join Us
              </Button>
            </Stack>
          </Grid>
        </>
      ))}
    </Grid>
  );
};

export default Events;
