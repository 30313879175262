import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Main from "../../../../elements/public/main";
import UserSearch from "./UserSearch";
import { Role, User, userInitState } from "./model";
import UserModify from "./UserModify";
import UserSearchList from "./UserSearchList";
import { getRoles } from "./services";

const UserManagemenDashboard: React.FC = () => {
  const [searchList, setSearchList] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User>(userInitState);
  const [open, setOpen] = useState<boolean>(false);

  const [roles, setRoles] = useState<Role[]>([]);

  useEffect(() => {
    
    const loadRoles = async () => {
      const roles = (await getRoles()).data;
      setRoles(roles);
    };
    loadRoles();

  }, []);

  const handleSelectUser = (id: string) => {
    const user = searchList.find((c) => c.id === id);
    if (user) {
      setSelectedUser(user);
      handleOpen();
    }
  };

  const handleAddNew = () => {
    setSelectedUser({ ...userInitState, roles: roles });
    handleOpen();
  };

  const handleSetSerachResult = (response: User[]) => {
    setSearchList(response);
  };

  const handleUpdateResult = (user: User) => {
    const finalList = searchList.filter((c) => c.id !== user.id);
    finalList.push(user);
    setSelectedUser(user);
    setSearchList(finalList);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    // setSelectedUser(userInitState);
  };
  return (
    <Main>
      <Grid container>
        <UserSearch
          setSearchList={handleSetSerachResult}
          searchList={searchList}
          handleNew={handleAddNew}
        />
        {searchList && searchList.length > 0 && (
          <Grid item xs={12}>
            <UserSearchList
              searchList={searchList}
              setSelectedUser={handleSelectUser}
            />
          </Grid>
        )}
        <UserModify
          user={selectedUser}
          open={open}
          handleClose={handleClose}
          handleUpdateResult={handleUpdateResult}
        />
      </Grid>
    </Main>
  );
};

export default UserManagemenDashboard;
