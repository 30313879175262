import React from "react";
import MyResponsiveLine from "./line.graph";
import { analyzeSubmission } from "../../services/classes.service";

class WordChangeLine extends React.Component<any, any> {
  // TODO add interface
  constructor(props: any) {
    super(props);
    this.formatData = this.formatData.bind(this);
    this.state = {
      data: null,
      maxY:200,
    };
  }

  async componentDidMount() {
    const wordChange = await analyzeSubmission(this.props.subid);
    this.setState({ data: wordChange.data });
    var maxY = Math.max(...wordChange.data.map((obj) => obj.y));
    maxY = Math.max(maxY+50,200)
    this.setState({ maxY });
  }
  formatData(e: any) {
    // TODO add interface
    const data = [
      {
        // id: "ASN",
        color: "hsl(10, 70%, 50%)",
        data: e,
      },
    ];

    return data;
  }

  render() {
    const { data,maxY } = this.state;
    return <>{data && <MyResponsiveLine data={data} maxY={maxY}/>}</>;
  }
}

export default WordChangeLine;
