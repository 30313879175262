import React from "react";
import { menus } from "./options";
import CustomMenuItem from "./MenuItem";
import { useSelector } from "react-redux";

const MenuDashboard: React.FC = () => {
  const roles: string[] = useSelector((state: any) => state.auth.user.roles);

  return (
    <>
      {menus.map((item) => ( roles.includes(item.access) &&
        <CustomMenuItem MenuGroup={item} />
      ))}
      
    </>
  );
};

export default MenuDashboard;
