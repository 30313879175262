import React from "react";
import { DraftSubmission } from "../../../../store/ClassDetails/model";

import { Chip, Stack } from "@mui/material";

interface DraftSubmissionsProps {
  drafts: DraftSubmission[];
  timezone: string;
}


const DraftSubmissionDetails: React.FC<DraftSubmissionsProps> = ({
  drafts,
}) => {
  const getColor = (status: string): string => {
    switch (status) {
      case "SUBMITTED":
        return "success";
      case "SUBMITTED_LATE":
        return "warning";
      case "SUBMITTED_INSTRUCTOR":
        return "warning";
      case "SUBMITTED_EXCEPTION":
          return "warning";
      case "SUBMITTED_AUTO":
        return "success";
      case "MISSED":
        return "error";
      case "NONE":
        return "primary";
      default:
        return "primary"; // or some default icon
    }
  };

  return (
    <Stack spacing={1}>
      {drafts.map((draft, index) => (
        // @ts-ignore
        <Chip label={draft.title} color={getColor(draft.status)} />
      ))}
    </Stack>
  );
};

export default DraftSubmissionDetails;
