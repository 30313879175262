import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { passwordRegex } from "../../services/models/patterns.regex";
import { FormDataProp, initFormData } from "./model";
import { updatePassword } from "../../services/auth.service";
import { setSnackbarMessage } from "../../store/UIActions/Snackbar/actions";
import { AlertTypes } from "../../store/UIActions/Snackbar/model";
import { useDispatch } from "react-redux";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
interface ResetPasswordProps {
  open: boolean;
  handleClose: () => void;
}

const ResetPassword: React.FC<ResetPasswordProps> = (props) => {
  const [formData, setFormData] = useState<FormDataProp>(initFormData);
  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const dispatch = useDispatch();
  const [visible, setVisible] = useState<boolean>(false);

  const validate = (): boolean => {
    var flag = true;
    if (formData.oldPassword === formData.newPassword) {
      dispatch(
        setSnackbarMessage({
          type: AlertTypes.ERROR,
          showSnackBar: true,
          message: `The new password has to be different than the current password.`,
        })
      );
      flag = false;
    }
    if (formData.renewPassword !== formData.newPassword) {
      dispatch(
        setSnackbarMessage({
          type: AlertTypes.ERROR,
          showSnackBar: true,
          message: `both the new passwords has to be the same.`,
        })
      );
      flag = false;
    }

    return flag;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      const response = await updatePassword(formData);
      if (response.status === 200) {
        setFormData(initFormData);
        dispatch(
          setSnackbarMessage({
            type: AlertTypes.SUCCESS,
            showSnackBar: true,
            message: `Password updated successfully`,
          })
        );
        props.handleClose();
      }
    } catch (error: any) {
      const messages =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      // Handle the Axios error
      dispatch(
        setSnackbarMessage({
          type: AlertTypes.ERROR,
          showSnackBar: true,
          message: `Error updating password: ${messages}`,
        })
      );
    }
  };
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth
      maxWidth="md"
      component="form"
      onSubmit={handleSubmit}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        <IconButton aria-label="close" onClick={props.handleClose}>
          <CloseIcon />
        </IconButton>
        Change Password
      </DialogTitle>

      <DialogContent dividers>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              autoFocus
              margin="normal"
              required
              fullWidth
              name="oldPassword"
              label="Current Password"
              type={visible ? "text" : "password"}
              id="oldPassword"
              value={formData.oldPassword}
              onChange={handleChange}
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onMouseEnter={() => setVisible(true)}
                      onMouseLeave={() => setVisible(false)}
                    >
                      {visible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="New Password"
              type={visible ? "text" : "password"}
              id="newPassword"
              value={formData.newPassword}
              onChange={handleChange}
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onMouseEnter={() => setVisible(true)}
                      onMouseLeave={() => setVisible(false)}
                    >
                      {visible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                pattern: passwordRegex.source,
              }}
              helperText="Password must be at least 8 characters and involve at least one of each of the following: uppercase letter, lowercase letter, a number, and a special character (!@$%&)."
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="renewPassword"
              label="Confirm New Password"
              type={visible ? "text" : "password"}
              id="renewPassword"
              value={formData.renewPassword}
              onChange={handleChange}
              variant="standard"
              inputProps={{
                pattern: passwordRegex.source,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onMouseEnter={() => setVisible(true)}
                      onMouseLeave={() => setVisible(false)}
                    >
                      {visible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={
                formData.renewPassword !== formData.newPassword &&
                "Must Match New Password."
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button type="submit" variant="text">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetPassword;
