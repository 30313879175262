import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import authHeader from "../../../../services/auth-header";
import dayjs, { Dayjs } from "dayjs";
import { Assignment } from "../../../../store/ClassDetails/model";
import { setSnackbarMessage } from "../../../../store/UIActions/Snackbar/actions";
import { AlertTypes } from "../../../../store/UIActions/Snackbar/model";

const HOST = process.env.REACT_APP_API_URL;
const CLASSES = "/api/classes/";
const LISTS = "getlist";
export interface EditClassDetailsProps {
  className: string;
  classTimeZone: string;
  enrollKey: string;
  id: string;
  startDate: Dayjs;
  endDate: Dayjs;
  studentLevel: string;
  subjectArea: string;
  studentLevelId: string;
  subjectAreaId: string;
  assignments: Assignment[];
}

export const initEditClassDetails: EditClassDetailsProps = {
  className: "",
  classTimeZone: "",
  enrollKey: "",
  id: "",
  startDate: dayjs(null),
  endDate: dayjs(null),
  studentLevel: "",
  subjectArea: "",
  studentLevelId: "",
  subjectAreaId: "",
  assignments: [],
};

export interface classLists {
  areaList: any[];
  levelList: any[];
}

export const initClassLists = { areaList: [], levelList: [] };

export type classListType = "area" | "level";

export const getClassLevels = async (type: classListType) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}${CLASSES}${LISTS}`,
    params: { type },
    data: {},
  };
  try {
    const apiCall: AxiosResponse<any[]> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const handleWarning = (msg: string,dispatch: any) => {
  dispatch(
    setSnackbarMessage({
      type: AlertTypes.WARNING,
      showSnackBar: true,
      message: `${msg}`,
    })
  );
};
