export const apiIds = {
  SUBMIT: "SUBMISSION",
};
export const TrackChangeMethods = {
  DEACTIVATE: "DeActivate",
  SHOW: "Show",
  FINALIZE: "Finalize",
  FILENAME: "tr.py",
  SPELLCHECK:"ActiveSpell"
};

export const isDueInLessThanSec = (
  deadline: Date,
  seconds: number
): boolean => {
  const now = new Date();
  const due = new Date(deadline);
  const remainingSeconds = (now.getTime() - due.getTime()) / 1000;
  const result = remainingSeconds > seconds && remainingSeconds < 0;
  return result;
};

export const deadLineStatusBySecond = (
  deadline: Date,
  seconds: number,
  toSeconds: number
): string => {
  var result: string = "Future";
  const now = new Date();
  const due = new Date(deadline);
  const remainingSeconds = (now.getTime() - due.getTime()) / 1000;
  // console.log(remainingSeconds);
  if (remainingSeconds > seconds && remainingSeconds < toSeconds) {
    result = "Present";
  } else if (remainingSeconds >= toSeconds) {
    result = "Passed";
  }

  return result;
};
