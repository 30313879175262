import React, { useEffect, useState } from "react";
import { tocustomTimeZoneSimple } from "../../../../../services/date.service";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CloseIcon from "@mui/icons-material/Close";
import UndoIcon from "@mui/icons-material/Undo";
import { Dayjs } from "dayjs";
import { useTheme } from "@mui/material/styles";

interface DraftSubmissionTableProps {
  draftSubmissions: [];
  timezone: string;
  finalDeadline: Dayjs;

  removeByIndex: (index: number) => void;
  loadDraftByIndex: (index: number) => void;
}

const dateConvert = (date: string | Dayjs, timezone: string): string | null => {
  try {
    const formattedDate: string =
      typeof date === "string" ? date : date.format("YYYY-MM-DD HH:mm:ss");

    return tocustomTimeZoneSimple(formattedDate, timezone);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const DraftSubmissionTable: React.FC<DraftSubmissionTableProps> = ({
  draftSubmissions,
  timezone,
  finalDeadline,
  removeByIndex,
  loadDraftByIndex,
}) => {
  const theme = useTheme();
  const dangerColor = theme.palette.error.light;
  const [finalScore, setFinalScore] = useState<number>(100);
  useEffect(() => {
    if (draftSubmissions.length > 0) {
      var draftTotalScore: number = 0;
      // @ts-ignore
      draftSubmissions
        // @ts-ignore
        .filter((b) => b.draftStatus !== "DELETE")
        // @ts-ignore
        .filter((b) => b.draftType !== "FINAL")
        .forEach((c) => {
          // @ts-ignore
          draftTotalScore += parseFloat(c.draftPointValue);
        });
      setFinalScore(100 - draftTotalScore);
    } else {
      setFinalScore(100);
    }
  }, [draftSubmissions]);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow key="head">
            <TableCell></TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Deadline</TableCell>
            <TableCell>Point Value</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {draftSubmissions
            // @ts-ignore
            .filter((b) => b.draftType !== "FINAL")
            .map((item: any, index: number) => (
              <TableRow
                key={index}
                sx={
                  item.draftStatus === "DELETE"
                    ? { backgroundColor: dangerColor }
                    : {}
                }
              >
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.draftTitle}</TableCell>
                <TableCell>
                  {
                    dateConvert(item.draftDeadline, timezone)
                    // tocustomTimeZoneSimple(
                    //   item.draftDeadline.format("YYYY-MM-DD HH:mm:ss"),
                    //   timezone
                    // )
                  }
                </TableCell>
                <TableCell>{item.draftPointValue}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => loadDraftByIndex(index)}
                  >
                    <ModeEditIcon />
                  </IconButton>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => removeByIndex(index)}
                  >
                    {item.draftStatus !== "DELETE" ? (
                      <CloseIcon
                        sx={{
                          color: "red",
                        }}
                      />
                    ) : (
                      <UndoIcon
                        sx={{
                          color: "green",
                        }}
                      />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          <TableRow key="final">
            <TableCell>
              {draftSubmissions.filter(
                // @ts-ignore
                (c) => c.draftType !== "FINAL"
              ).length + 1}
            </TableCell>
            <TableCell>Final Submission</TableCell>
            <TableCell>{dateConvert(finalDeadline, timezone)}</TableCell>
            <TableCell>{finalScore}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default DraftSubmissionTable;
