import React, { useState } from "react";
import { Assignment } from "../../../../store/ClassDetails/model";
import { useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { tocustomTimeZoneSimple } from "../../../../services/date.service";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";

import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { updateDraftPeerReviewDeadline } from "./services";
import { useDispatch } from "react-redux";
import { updateDraftPeerReviewDeadlineAction } from "../../../../store/ClassDetails/actions";
import { setIsLoading } from "../../../../store/UIActions/Loading/actions";
import { apiIds } from "../../../../store/ClassDetails/constants";
import { setSnackbarMessage } from "../../../../store/UIActions/Snackbar/actions";
import { AlertTypes } from "../../../../store/UIActions/Snackbar/model";
import { PeerReviewDialogProps } from "./model";

dayjs.extend(utc);
dayjs.extend(timezone);

const DeadlineTab: React.FC<PeerReviewDialogProps> = ({
  assignmentId,
  setUnsaved
}) => {
  const assignment: Assignment = useSelector((state: any) =>
    state.classDetails.assignments.find((c: any) => c.id === assignmentId)
  );

  const timezone: string = useSelector(
    (state: any) => state.classDetails.classTimeZone
  );

  const [deadlines, setDeadlines] = useState<any[]>(
    assignment?.draftSubmissions.map((c) => {
      return {
        ...c,
        included: c.draftPeerReviewDeadline ? true : false,
        suggestedDate:
          c.draftPeerReviewDeadline || dayjs(c.draftDeadline).add(2, "day"),
      };
    })
  );


  const dispatch = useDispatch();

  const handleChange = (e: any, id: string) => {
    try {
      const date = dayjs(e.toDate()).tz(timezone);

      const updatedReviewDate = deadlines.map((item) => {
        if (item.draftId === id)
          return {
            ...item,
            draftPeerReviewDeadline: date,
            draftStatus: "UPDATE",
          };
        return { ...item };
      });
      setUnsaved("deadline",true);
      setDeadlines(updatedReviewDate);
    } catch {
      return;
    }
  };

  const handleToggleInclude = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    setDeadlines((prevState: any[]) => {
      return prevState.map((c) => {
        if (c.draftId !== id) return c;
        if (!event.target.checked) {
          return {
            ...c,
            included: event.target.checked,
            suggestedDate: c.draftPeerReviewDeadline || c.suggestedDate,
            draftPeerReviewDeadline: null,
          };
        }
        return {
          ...c,
          included: event.target.checked,
          draftPeerReviewDeadline: c.suggestedDate,
        };
      });
    });
    setUnsaved("deadline",true);
  };

  const handleUpdate = async () => {
    const draftsDeadline = deadlines.map((item) => {
      return {
        draftId: item.draftId,
        draftPeerReviewDeadline: item.draftPeerReviewDeadline,
      };
    });
    try {
      dispatch(setIsLoading(apiIds.updateDraftPeerReviewDeadline));
      const response = await updateDraftPeerReviewDeadline([...draftsDeadline]);
      if (response.status === 200) {
        const drafts = deadlines;
        // @ts-ignore
        dispatch(updateDraftPeerReviewDeadlineAction({ assignmentId, drafts }));
        dispatch(
          setSnackbarMessage({
            type: AlertTypes.SUCCESS,
            showSnackBar: true,
            message: `Peer review deadline(s) set successfully.`,
          })
        );
      setUnsaved("deadline",false);

      }
    } catch {}
  };
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Draft Title</TableCell>
            <TableCell>Submission Deadline</TableCell>
            <TableCell>Point Value</TableCell>
            <TableCell>Review Deadline</TableCell>
            <TableCell>Activate?</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {deadlines.map((item) => (
            <TableRow>
              <TableCell>{item.draftTitle}</TableCell>
              <TableCell>
                {tocustomTimeZoneSimple(
                  item.draftDeadline.toString(),
                  timezone
                )}
              </TableCell>
              <TableCell>{item.draftPointValue}</TableCell>
              <TableCell>
                {timezone && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label={`Deadline : ${timezone}`}
                      value={dayjs(item.draftPeerReviewDeadline)}
                      minDateTime={dayjs(item.draftDeadline).tz(timezone)}
                      onChange={(newValue) => {
                        handleChange(newValue, item.draftId);
                      }}
                      disabled={!item.included}
                      // format="YYYY-MM-DD HH:mm"
                      key={item.id}
                      sx={{ width: "100%" }}
                      timezone={timezone}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                    />
                  </LocalizationProvider>
                )}
              </TableCell>
              <TableCell>
                {/* <IconButton sx={{ color: "red" }}>
                  <CloseIcon onClick={() => handleClear(item.draftId)} />
                </IconButton> */}
                <Checkbox
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<AddTaskOutlinedIcon color="success" />}
                  checked={item.included}
                  onChange={(e) => handleToggleInclude(e, item.draftId)}
                />
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={3}></TableCell>
            <TableCell>
              <Button
                fullWidth
                variant="contained"
                endIcon={<EventAvailableIcon />}
                onClick={handleUpdate}
              >
                Apply
              </Button>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default DeadlineTab;
