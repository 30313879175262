export interface ContactUsInfo{
    name: string;
    email:string;
    message:string;
}

export const initiateContactUs:ContactUsInfo = {
    name:"",
    email:"",
    message:""
}

