import { CONSTRAINTS } from "../../elements/Support/constraints";

import { ContactUsInfo } from "./model";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";


export const sendContactUs = async (model: ContactUsInfo) => {
  const isSupport = process.env.REACT_APP_SUPPORT;

  const support_apr = `${CONSTRAINTS.HOST}/${CONSTRAINTS.SUPPORT}/${CONSTRAINTS.CONTACT}`;
  const email_api = `${CONSTRAINTS.HOST}/${CONSTRAINTS.EMAIL}/${CONSTRAINTS.EMAIL_CONTACT}`;
  const url = isSupport==="true" ? support_apr:email_api;
  const axiosConfig: AxiosRequestConfig = {
    method: "POST",
    url,
    data: {
      ...model,
    },
  };
  try {
    const apiCall: AxiosResponse<String> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};
