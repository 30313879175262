/* eslint-disable import/no-anonymous-default-export */
import { ActionType, SET_DRAWER_CLOSE, SET_DRAWER_OPEN } from "./actions";
import { DrawerState, initialState } from "./model";

export default (state = initialState, action: ActionType): DrawerState => {
  const { type } = action;

  switch (type) {
    case SET_DRAWER_CLOSE:
      // @ts-ignore
      localStorage.setItem("isDrawerOpen", false);
      return {
        ...state,
        isDrawerOpen: false,
      };
    case SET_DRAWER_OPEN:
      // @ts-ignore
      localStorage.setItem("isDrawerOpen", true);
      return {
        ...state,
        isDrawerOpen: true,
      };
    default:
      return state;
  }
};
