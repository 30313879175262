import { PeerReview } from "../../../store/ReadAssignment/model";

export interface StudentAssignmentData {
  assignment: AssignmentDetail;
  submissionId: string;
}

export const initialState: StudentAssignmentData = {
  assignment: {} as AssignmentDetail,
  submissionId: "",
};

export interface CommentedFile {
  fileId: string;
  commentDate: Date;
}

export interface deadline {
  draftId: string;
  draftStatus: string;
  draftTitle: string;
  draftType: string;
  draftPointValue: number;
  draftDeadline: Date;
}

export interface timeline {
  draftId: string;
  title: string;
  date: Date;
  submitDate: Date;
  status: string;
  exception: boolean;
  fileId: string;
  commentFiles: CommentedFile[];
  peerReviews: PeerReview[];
}

export interface Feedback {
  comment: string;
  timestamp: Date;
  read: boolean;
  draftId: string;
  draftTitle: string;
}

interface submission {
  id: string;
  createDate: Date;
  submitDate: Date;
  score: number;
  status: string;
  feedbacks: Feedback[];
}

export interface AssignmentDetail {
  assignmentId: string;
  courseId: string;
  title: string;
  pointValue: number;
  description: string;
  startDate: Date;
  endDate: Date;
  postDate: Date;
  preventAfterDueDate: boolean;
  autoSubmission: boolean;
  autoAccept: boolean;
  showAnalytics: boolean;
  deadlines: deadline[];
  timeline: timeline[];
  submissions: submission[];
}

export type TimelinePage = "step" | "activity";

export const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 0,
};