import React, { useId, useState } from "react";
import { ReviewsRowProps } from "./model";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const ReviewsRow: React.FC<ReviewsRowProps> = ({ row ,handleOpenPreview}) => {
  const [open, setOpen] = useState<boolean>(false);
  const headers = useId()
  const header2 = useId()
  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }} key={headers}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.reviewee}</TableCell>
      </TableRow>
      <TableRow key={header2}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow key={`row-${row.revieweeId}`}>
                    <TableCell>Draft</TableCell>
                    <TableCell>Reviewer</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* TODO add interface */}
                  {row.pr.map((item) => (
                    <TableRow key={`pr-${item.fileId}`}>
                      <TableCell>{item.title}</TableCell>
                      <TableCell>{item.reviewer}</TableCell>
                      <TableCell>{item.status}</TableCell>
                      <TableCell align="right" ><Button variant="contained" onClick={()=>handleOpenPreview(item.fileId)}>Preview</Button></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ReviewsRow;
