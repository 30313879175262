import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";

interface SubmissionNoticeProps {
  handleSubmit: (draftId: string) => void;
  handleClose: () => void;
  open: boolean;
  draftTitle: string;
  draftId: string;
  autoAccept: boolean;
}

const SubmissionNotice: React.FC<SubmissionNoticeProps> = (props) => {
  const handleSubmission = () => {
    props.handleSubmit(props.draftId);
    props.handleClose();
  };
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">Submission Notice</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body2">
            Are you sure you wish to submit <strong>{props.draftTitle}</strong>?
            You cannot undo this task. The Antecedent system saves your writing
            as you type, so if plan to write more for this draft, you do not
            need to submit the draft. If you would like to save a backup copy,
            you can download a version to your local computer.
          </Typography>

          {props.autoAccept === true && (
            <>
              <br />
              <Typography variant="body2">
                Be aware that upon submission, all unresolved tracked
                changes will be automatically accepted, and any existing
                comments will be removed.
              </Typography>
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={props.handleClose}>
          Disagree
        </Button>
        <Button onClick={handleSubmission} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubmissionNotice;
