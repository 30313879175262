import React from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { getGradeByScore, getScoreColor } from "../../services/grade.service";

function CircleBar(props: any) {
  const { score } = props;
  return (
    <CircularProgressbarWithChildren
      value={score}
      styles={buildStyles({
        pathColor: getScoreColor(score),
        trailColor: "transparent",
      })}
    >
      {/* Put any JSX content in here that you'd like. It'll be vertically and horizontally centered. */}

      <div style={{ fontSize: 24, marginTop: -5 }}>
        <strong>{getGradeByScore(score)}</strong>
      </div>
    </CircularProgressbarWithChildren>
  );
}
export default CircleBar;