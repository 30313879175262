import React from 'react';
import Termscondition from '../../elements/public/term-condition';
import Main from '../../elements/public/main';
import { useParams } from 'react-router-dom';
import Privacy from '../../elements/public/privacy';
const TermCondition = () => {
    const {type} = useParams()
    return (
        <Main sx={{ overflow: "hidden"}}>
            {type === "termsandconditions" && (<Termscondition />)}
            {type === "privacy" && (<Privacy />)}
            
        </Main>
    );
};

export default TermCondition