import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import authHeader from "../../../../services/auth-header";
import { MutalReviewType } from "./model";
import { ClassDetails } from "../../../../store/ClassDetails/model";

const HOST = process.env.REACT_APP_API_URL;
const PEERREVIEW = "api/peerreview";
const RANDOMIZE = "Randomize";
const POPULATE = "populate";
const DEADLINE = "deadline";

export const randomizeReviwer = async (
  size: number,
  options: MutalReviewType = "Optional",
  reviewer: number = 1
) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${PEERREVIEW}/${RANDOMIZE}`,
    params: {},
    data: { size, options, reviewer },
  };
  try {
    const apiCall: AxiosResponse<number[][]> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const setReviewers = async (assignmentId: String, peerReview: any[]) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${PEERREVIEW}/${POPULATE}`,
    params: {},
    data: { assignmentId, peerReview },
  };
  try {
    const apiCall: AxiosResponse<ClassDetails> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const updateDraftPeerReviewDeadline = async (request: any[]) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${PEERREVIEW}/${DEADLINE}`,
    data: request,
    params: {},
  };
  try {
    const apiCall: AxiosResponse<string> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};
