import React from "react";

import { ResponsiveBoxPlot } from "@nivo/boxplot";
import { Box, Paper, Typography } from "@mui/material";

interface BoxPLotProps {
  data: any[] | undefined;
}

const CustomTooltip = ({ data }: { data: any }) => {
  return (
    <Paper
      elevation={3}
      style={{
        padding: "16px",
        backgroundColor: "white",
        borderRadius: "8px",
        maxWidth: "200px",
      }}
    >
      <Typography
        variant="h6"
        style={{ fontWeight: "bold", marginBottom: "8px" }}
      >
        {data.group}
      </Typography>
      <Box>
        <Typography variant="body2">
          {" "}
          Number of Assignments: {data.n}
        </Typography>
      </Box>
      <Typography variant="subtitle1" style={{ margin: "8px 0" }}>
        Quantiles
      </Typography>
      <Typography variant="body2">10%: {data.values[0]}</Typography>
      <Typography variant="body2">25%: {data.values[1]}</Typography>
      <Typography variant="body2">50%: {data.values[2]}</Typography>
      <Typography variant="body2">75%: {data.values[3]}</Typography>
      <Typography variant="body2">90%: {Math.round(data.values[4])}</Typography>
      <Typography variant="subtitle1" style={{ margin: "8px 0" }}>
        Summary
      </Typography>
      <Typography variant="body2">mean: {Math.round(data.mean)}</Typography>
      <Typography variant="body2">min: {data.values[0]}</Typography>
      <Typography variant="body2">max: {Math.round(data.values[4])}</Typography>
    </Paper>
  );
};

const WPMBoxPlot: React.FC<BoxPLotProps> = ({ data }) => {
  return (
    <ResponsiveBoxPlot
      data={data}
      margin={{ top: 60, right: 140, bottom: 60, left: 60 }}
      subGroupBy="subgroup"
      padding={0.12}
      enableGridX={true}
      //   quantiles={[0, 0.25, 0.5, 0.75, 1]}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Assignments",
        legendPosition: "middle",
        legendOffset: 32,
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Words Per Minute",
        legendPosition: "middle",
        legendOffset: -40,
        truncateTickAt: 0,
      }}
      colors={{ scheme: "nivo" }}
      borderRadius={2}
      borderWidth={2}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.3]],
      }}
      medianWidth={2}
      medianColor={{
        from: "color",
        modifiers: [["darker", 0.3]],
      }}
      whiskerEndSize={0.6}
      whiskerColor={{
        from: "color",
        modifiers: [["darker", 0.3]],
      }}
      motionConfig="stiff"
      tooltip={(e) => {
        return <CustomTooltip data={e["data"]} />;
      }}
      legends={[
        {
          anchor: "right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemWidth: 60,
          itemHeight: 20,
          itemsSpacing: 3,
          itemTextColor: "#999",
          itemDirection: "left-to-right",
          symbolSize: 20,
          symbolShape: "square",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
    />
  );
};

export default WPMBoxPlot;
