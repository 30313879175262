import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Link } from "react-scroll";

const AboutUs = (props: any) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
      sx={{ my: 5, height: "40vh" }}
      {...props}
    >
      <Grid
        item
        lg={4}
        md={6}
        sm={12}
        xs={12}
        direction="column"
        justifyContent="space-around"
        alignItems="center"
        spacing={3}
        sx={{ display: "flex" }}
      >
        <Typography variant="h2" color="primary" sx={{ fontWeight: "bold" }}>
          About Us
        </Typography>
        <Typography variant="body1" sx={{ m: 2 }} textAlign="center">
          At Antecedent, we believe that the best way to assess the integrity of
          student writing in the AI writing era is through the depth and quality
          of students' effort while they are drafting and revising assignments.
          In essence, Antecedent ensures that students <i>honor the process.</i>
        </Typography>
        <Button variant="contained">
          <Link
            to="contactus"
            smooth={true}
            duration={750}
            offset={-100}
            spy={true}
          >
            Contact Us
          </Link>
        </Button>
      </Grid>
      <Grid
        item
        md={4}
        sx={{
          display: { xs: "none", sm: "none", md: "flex" },
          backgroundImage: "url(" + process.env.PUBLIC_URL + "/splash2.jpg)",
          boxShadow: "0px 0px 10px 5px rgba(0, 0, 0, 0.5)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          ml: 10,
        }}
        spacing={3}
      ></Grid>
    </Grid>
  );
};

export default AboutUs;
