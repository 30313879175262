import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import authHeader from "../../../services/auth-header";
import { AssignmentDetail } from "./model";

import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

const HOST = process.env.REACT_APP_API_URL;
const STUDENT = "api/student";
const ASSIGNMENT = "assignment";
const SUBMISSION = "submission";

export const getStudentAssignment = async (assignmentId: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "GET",
    url: `${HOST}/${STUDENT}/${ASSIGNMENT}`,
    data: {},
    params: { id: assignmentId },
  };
  try {
    // TODO add interface
    const apiCall: AxiosResponse<AssignmentDetail> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const newDocument = async (assignmentId: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${STUDENT}/${ASSIGNMENT}`,
    data: {},
    params: { id: assignmentId },
  };
  try {
    // TODO add interface
    const apiCall: AxiosResponse<AssignmentDetail> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

/**
 *
 * @param id submissionId
 * @returns
 */
export const submission = async (id: string, draftId: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${STUDENT}/${SUBMISSION}`,
    data: {},
    params: { id, draftId },
  };
  try {
    // TODO add interface
    const apiCall: AxiosResponse<AssignmentDetail> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const dateFormat = (ms: number) => {
  const date = dayjs(ms);
  const formattedDate = date.format("YYYY-MM-DD HH:mm");
  return `${formattedDate}`;
};


