import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Countdown } from "../../../components/Countdown";
import dayjs from "dayjs";
import { setSnackbarMessage } from "../../../store/UIActions/Snackbar/actions";
import { AlertTypes } from "../../../store/UIActions/Snackbar/model";
import Timeline from "./Timeline/Timeline";
import { Feedback, TimelinePage, deadline, timeline } from "./model";
import { deadLineStatusBySecond } from "./utils";
import FeedbackItem from "./FeedbackItem";
import ActivityTimeLine from "./Timeline/ActivityTimeLine";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MAX_TIMEOUT = 86400000;
interface DetailsProps {
  title: string;
  submissionId: string;
  description: string;
  dueDate: string;
  startDate: Date;
  dueDateAMPM: string;
  deadlineEnforcement: string;
  autoAccept: boolean;
  autoSubmission: string;
  weight: number;
  exception: boolean;
  timelines: timeline[];
  deadlines: deadline[];
  feedbacks: Feedback[];
  showAnalytics: boolean;
  dispatch: (event: any) => void;
  handleStart: () => void;
  handleSubmit: (draftId: string) => void;
  setFileId: (fileId: string) => void;
  handleAnalyticsOpen: () => void;
}

const Details: React.FC<DetailsProps> = ({
  dueDate,
  submissionId,
  deadlineEnforcement,
  title,
  description,
  autoSubmission,
  startDate,
  dueDateAMPM,
  weight,
  timelines,
  deadlines,
  feedbacks,
  exception,
  autoAccept,
  showAnalytics,
  dispatch,
  handleStart,
  handleSubmit,
  setFileId,
  handleAnalyticsOpen,
}) => {
  const [timelinePage, setTimelinePage] = useState<TimelinePage>("step");
  const [dueDateWarning, setDueDateWarning] = useState(false);
  const ASSIGNMENT_TIMER_AUTO_SUBMISSION = -3600; // in seconds, 1hour remaining
  const TEN_MINUTES = -600; // in seconds

  // const autoRefreshIn = new Date(dueDate).getTime() - new Date().getTime(); // in milliseconds
  const [autoRefreshIn, setAutoRefreshIn] = useState<any>(() => {
    const timeUntilDueDate = new Date(dueDate).getTime() - new Date().getTime();
    return Math.min(timeUntilDueDate, MAX_TIMEOUT);
  });
  const [hourIntervals, setHourIntervals] = useState<NodeJS.Timeout[]>([]);
  const [tenMinIntervals, setTenMinIntervals] = useState<NodeJS.Timeout[]>([]);
  const [nextDeadline, setNextDeadline] = useState<Date>();
  // this is responsible for showing the countdown timer in the warning and show a snackbar that assignment
  // is due soon

  const handleSwitchPage = () => {
    setTimelinePage((prevPage) => {
      switch (prevPage) {
        case "step":
          return "activity";
        case "activity":
          return "step";
        default:
          return "step";
      }
    });
  };

  useEffect(() => {
    if (deadlineEnforcement === "Yes") {
      const next = deadlines.find(
        (c) => new Date(c.draftDeadline) > new Date()
      );
      setNextDeadline(next?.draftDeadline);

      if (next) {
        setAutoRefreshIn(
          Math.min(
            new Date(next.draftDeadline).getTime() - new Date().getTime(),
            MAX_TIMEOUT
          )
        );
      }
      deadlines.forEach(() => {
        const intervalId = setInterval(() => {}, 1000);
        setHourIntervals((prevIntervals) => [...prevIntervals, intervalId]);
      });

      const checkDeadLines = () => {
        if (deadlines.length > 0) {
          setHourIntervals((prevIntervals) => {
            return prevIntervals.filter((intervalId, index) => {
              try {
                const status = deadLineStatusBySecond(
                  deadlines[index].draftDeadline,
                  ASSIGNMENT_TIMER_AUTO_SUBMISSION,
                  TEN_MINUTES
                );

                if (status === "Present") {
                  clearInterval(intervalId);
                  dispatch(
                    setSnackbarMessage({
                      type: AlertTypes.WARNING,
                      showSnackBar: true,
                      message: `${deadlines[index].draftTitle} is due soon!`,
                    })
                  );
                  setDueDateWarning(true);
                  return false; // Remove the interval from the list
                } else if (status === "Passed") {
                  clearInterval(intervalId);
                  return false;
                }
                return true; // Keep the interval in the list
              } catch {
                return true; // Keep the interval in the list
              }
            });
          });
        }
      };
      const deadlineInterval = setInterval(checkDeadLines, 1000);
      return () => {
        hourIntervals.forEach((intervalId) => clearInterval(intervalId));
        clearInterval(deadlineInterval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deadlines]);

  useEffect(() => {
    if (deadlineEnforcement === "Yes") {
      deadlines.forEach(() => {
        const intervalId = setInterval(() => {}, 1000);
        setTenMinIntervals((prevIntervals) => [...prevIntervals, intervalId]);
      });

      const checkDeadLines = () => {
        setTenMinIntervals((prevIntervals) => {
          return prevIntervals.filter((intervalId, index) => {
            try {
              const status = deadLineStatusBySecond(
                deadlines[index].draftDeadline,
                TEN_MINUTES,
                0
              );
              if (status === "Present") {
                clearInterval(intervalId);
                dispatch(
                  setSnackbarMessage({
                    type: AlertTypes.WARNING,
                    showSnackBar: true,
                    message: `${deadlines[index].draftTitle} is due in less than 10 minutes!`,
                  })
                );
                setDueDateWarning(true);
                return false; // Remove the interval from the list
              } else if (status === "Passed") {
                clearInterval(intervalId);
                return false;
              }

              return true; // Keep the interval in the list
            } catch {
              return true;
            }
          });
        });
      };
      const deadlineInterval = setInterval(checkDeadLines, 1000);
      return () => {
        tenMinIntervals.forEach((intervalId) => clearInterval(intervalId));
        clearInterval(deadlineInterval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deadlines]);

  // this is responsible for auto refreshing page at the due date time
  useEffect(() => {
    let timer: any;

    if (deadlineEnforcement === "Yes") {
      //if the due date has passed, no need for a timer, time in ms
      // console.log(autoRefreshIn);
      if (autoRefreshIn > 1000) {
        timer = setTimeout(() => window.location.reload(), autoRefreshIn);
      }
    }
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoRefreshIn, dueDateWarning]);

  return (
    <Grid item xs={12} md={12} sx={{ mt: 1 }}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Assignment Summary
        </AccordionSummary>
        <AccordionDetails>
          <Paper>
            <Grid container spacing={2} m={0}>
              <Grid item xs={12} md={4} sx={{ bgcolor: "text.hint" }}>
                Title
              </Grid>
              <Grid item xs={12} md={8}>
                {title}
              </Grid>
              <Grid item xs={12} md={4} sx={{ bgcolor: "text.hint" }}>
                Final Submission Deadline
              </Grid>
              <Grid item xs={12} md={8}>
                {dueDateAMPM}
              </Grid>
              {deadlineEnforcement === "Yes" && (
                <>
                  <Grid item xs={12} md={4} sx={{ bgcolor: "text.hint" }}>
                    Deadline Enforcement
                  </Grid>

                  <Grid item xs={12} md={8}>
                    {deadlineEnforcement}
                  </Grid>
                </>
              )}

              <Grid item xs={12} md={4} sx={{ bgcolor: "text.hint" }}>
                Autosubmission
              </Grid>
              <Grid item xs={12} md={8}>
                {autoSubmission}
              </Grid>
              <Grid item xs={12} md={4} sx={{ bgcolor: "text.hint" }}>
                Weighted Distribution
              </Grid>
              <Grid item xs={12} md={8}>
                {weight}%
              </Grid>

              <Grid item xs={12} md={4} sx={{ bgcolor: "text.hint" }}>
                Description
              </Grid>
              <Grid item xs={12} md={8} sx={{ p: 3 }}>
                {description}
              </Grid>
              {feedbacks.length > 0 && (
                <>
                  <Grid item xs={12} md={4} sx={{ bgcolor: "text.hint" }}>
                    Feedback
                  </Grid>
                  <Grid item xs={12} md={8} sx={{ p: 3 }}>
                    {feedbacks.map((item, index) => {
                      const isDifferentFromPrevious =
                        index === 0 ||
                        item.draftTitle !== feedbacks[index - 1].draftTitle;
                      return (
                        <>
                          {isDifferentFromPrevious && (
                            <Divider>
                              <Chip label={item.draftTitle} size="small" />
                            </Divider>
                          )}

                          <FeedbackItem
                            feedback={item}
                            key={`feedback-${index}`}
                          />
                        </>
                      );
                    })}
                  </Grid>
                </>
              )}

              {timelines.length > 0 && (
                <>
                  <Grid item xs={12} md={4} sx={{ bgcolor: "text.hint" }}>
                    Draft Submission{" "}
                    {timelinePage === "step" ? "Steps" : "Timeline"}
                    <IconButton
                      aria-label="switchPage"
                      onClick={handleSwitchPage}
                    >
                      <NavigateNextIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} md={8} sx={{ p: 3 }}>
                    <Collapse
                      // direction="up"
                      in={timelinePage === "step"}
                      mountOnEnter
                      unmountOnExit
                    >
                      <Grid item xs={12}>
                        <Timeline
                          timeline={timelines}
                          handleStart={handleStart}
                          handleSubmit={handleSubmit}
                          deadlineEnforcement={deadlineEnforcement === "Yes"}
                          exception={exception}
                          autoAccept={autoAccept}
                        />
                      </Grid>
                    </Collapse>
                    <Collapse
                      // direction="up"
                      in={timelinePage === "activity"}
                      mountOnEnter
                      unmountOnExit
                    >
                      <Grid item xs={12}>
                        <ActivityTimeLine
                          submissionId={submissionId}
                          deadlines={deadlines}
                          startDate={startDate}
                          timelines={timelines}
                          setFileId={setFileId}
                        />
                      </Grid>
                    </Collapse>
                  </Grid>
                </>
              )}
              {dueDateWarning && nextDeadline ? (
                <Grid
                  sx={{
                    color: "red",
                    paddingLeft: "5px",
                  }}
                >
                  Caution! This task is subject to deadline enforcement. It will
                  automatically submit in {""}{" "}
                  <Countdown endTime={dayjs(nextDeadline)} />
                </Grid>
              ) : (
                ""
              )}
              {showAnalytics && (
                <>
                  <Grid item xs={12} md={4} sx={{ bgcolor: "text.hint" }}>
                    <Button
                      fullWidth
                      variant="text"
                      onClick={handleAnalyticsOpen}
                    >
                      Analytics
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={8}></Grid>
                </>
              )}
            </Grid>
          </Paper>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default Details;

// (new Date() - new Date("2022-08-14 12:00"))
