export interface ClassDetails {
  assignments: Assignment[];
  className: string;
  classTimeZone: string;
  endDate: string;
  enrollKey: string;
  id: string;
  startDate: string;
  studentLevel: string;
  students: Student[];
  subjectArea: string;
}

export interface Reviews {
  reviewerId: string;
  reviewer: string;
  status: string;
  fileId: string;
}

export interface SimpleStudent {
  id: string;
  name: string;
}

export interface PeerReviewBin {
  id: string;
  name: string;
  reviewers: SimpleStudent[];
}

export interface Assignment {
  id: string;
  description: string;
  endDate: string;
  postDate: string;
  startDate: string;
  title: string;
  autoSubmission: boolean;
  autoEvaluate: boolean;
  autoAccept: boolean;
  preventAfterDueDate: boolean;
  visible: boolean;
  showAnalytics: boolean;
  stoped: boolean;
  draftSubmissions: any[];
  peerReview: PeerReviewBin[];
}
export const AssignmentInit: Assignment = {
  id: "",
  description: "",
  endDate: "",
  postDate: "",
  startDate: "",
  title: "",
  autoSubmission: false,
  autoEvaluate: false,
  autoAccept: false,
  preventAfterDueDate: false,
  visible: true,
  showAnalytics: false,
  stoped: false,
  draftSubmissions: [],
  peerReview: [],
};

export interface Student {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  totalSubmission: number;
  totalScore: number;
  totalTime: number;
  submissions: Submission[];
}
export const StudentInit = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  totalSubmission: 0,
  totalScore: 0.0,
  submissions: [],
  totalTime: -1,
};

export const initialState: ClassDetails = {
  assignments: [],
  className: "",
  endDate: "",
  enrollKey: "",
  id: "",
  startDate: "",
  studentLevel: "",
  students: [],
  subjectArea: "",
  classTimeZone: "",
};

export interface CreateAssignmentBody {
  id: string;
  title: string;
  pointValue: string;
  startDate: Date;
  endDate: Date;
  postDate: Date;
  preventAfterDueDate: boolean;
  autoSubmission: boolean;
  autoEvaluate: boolean;
  autoAccept: boolean;
  showAnalytics: boolean;
  showAnalyticsAlways: boolean;
  description: string;
  draftSubmissions: any[];
}

export interface ClassStudentData {
  classId: string;
  studentId: string;
}

export interface Submission {
  submissionId: string;
  assignmentId: string;
  createDate: Date;
  score: -1.0;
  status: string;
  lastChangeDate: Date;
  draftSubmissions: DraftSubmission[];
  late: boolean;
  totalTime: number;
}

export interface DraftSubmission {
  title: string;
  deadline: Date;
  submissionDate: Date;
  status: string;
  peerReviews: Reviews[];
}

export interface UpdateClassDetails {
  id: string;
  classname: string;
  subjectarea: string;
  studentlevel: string;
  classStartDate: Date;
  classEndDate: Date;
  classTimeZone: string;
}

export interface UpdateDraftPeerReviewDeadlinePayload {
  assignmentId: string;
  drafts: any[];
}

export interface UpdateAssignmentStopStatusPayload {
  assignmentId: string;
  status: boolean;
}
