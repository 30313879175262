export interface ClassListItem {
  id: string;
  title: string;
  instructorFirstName: string;
  instructorLastName: string;
  subjectArea: string;
  studentLevel: string;
  startDate: Date;
  endtDate: Date;
  timezone: string;
  numberOfStudents: number;
}

export interface ClassSubmission {
  submissionId: string;
  assignmentId: string;
  createDate: Date;
  score: number;
  status: string;
  lastChangeDate: Date;
  late: boolean;
}

export interface Student {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  totalSubmission: number;
  totalScore: number;
  submissions: ClassSubmission[];
}
export interface EmbededAssignment {
  id: string;
  title: string;
  pointValue: number;
  startDate: Date;
  endDate: Date;
  postDate: Date;
  description: string;
  preventAfterDueDate: boolean;
  autoSubmission: boolean;
  autoEvaluate: boolean;
}
export interface ClassDetails {
  id: string;
  className: string;
  enrollkey: string;
  subjectArea: string;
  studentLevel: string;
  startDate: Date;
  endDate: Date;
  classTimeZone: string;
  students: Student[];
  assignments: EmbededAssignment[];
}

export const StudentInit: Student = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  totalSubmission: 0,
  totalScore: 0.0,
  submissions: [],
};

interface ScoresHistory {
  timestamp: Date;
  effort: number;
  sincerity: number;
  revision: number;
  unoriginality: number;
  type: string;
  graded: boolean;
}

export interface SubmissionDetails {
  studentId: string;
  studentName: string;
  classId: string;
  classTitle: string;
  assignmentId: string;
  assignmentTitle: string;
  currentStudentIndex: number;
  numberOfStudents: number;
  status: string;
  grade: number;
  effort: number;
  sincerity: number;
  revision: number;
  unoriginality: number;
  lastSubmission: Date;
  assignmentDuedate: Date;
  lastModification: Date;
  scoresHistory: ScoresHistory[];
  feedbacks: [];
}

interface _TimeMetricItem {
  d: number;
  h: number;
  m: number;
  s: number;
}

export interface TimeMetricsDetails {
  active: _TimeMetricItem;
  think: _TimeMetricItem;
  real: _TimeMetricItem;
}

export const GraphMap: Map<string, string> = new Map([
  ["0", "EssayMap"],
  ["1", "HeatMap"],
  ["2", "WordChange"],
]);

export interface ClassListProps {
  index: number;
  ended: boolean;
}

export const initClassListProps: ClassListProps = {
  index: 0,
  ended: false,
};
