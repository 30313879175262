import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Main from "../../../elements/public/main";
import authToken from "../../../services/auth-token";
import { validate } from "../../../actions/auth";

import { Grid, Container } from "@mui/material";
import { useDispatch } from "react-redux";
import { SubmissionDetails, TimeMetricsDetails } from "../utils/model";
import {
  getSubmissionDetails,
  getSubmissionTimeMetrics,
} from "../utils/service";
import Panel from "./Panel";
import { getSubmissionLogsAction } from "../../../store/ReadAssignment/actions";
const EvaluateAssignment = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const EDITOR_URL = process.env.REACT_APP_EDU_EDITOR_URL;
  const target = "collabora-online-viewer";
  const [data, setData] = useState<SubmissionDetails>();
  const [timeMetrics, setTimeMetrics] = useState<TimeMetricsDetails>();

  
  
  // Validate user on load
  useEffect(() => {
    // @ts-ignore
    dispatch(validate());
    // @ts-ignore
    dispatch(getSubmissionLogsAction(id));
    const formformExists: any = document.getElementById(
      "collabora-submit-form"
    );

    if (formformExists) {
      formformExists.submit();
    }

  }, [dispatch,id]);

  // fetch data on load
  useEffect(() => {
    const fetchData = async () => {
      try {
        // @ts-ignore
        const response = await getSubmissionDetails(id);
        // @ts-ignore
        setData(response.data);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };
    const getSubmissionTime = async () => {
      try {
        // @ts-ignore
        const response = await getSubmissionTimeMetrics(id);
        // @ts-ignore
        setTimeMetrics(response.data);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    Promise.all([fetchData(), getSubmissionTime()])
      .then(() => {
        // Both functions have completed
      })
      .catch((err) => {
        console.error(
          "Error running fetchData and getSubmissionTime in parallel:",
          err
        );
      });
  }, [id]);

  return (
    <Main>
      <Grid container spacing={3}>
        <Grid item md={3} lg={2} sm={12} xs={12} sx={{ mt: 3 }}>
          {data && id!==undefined && <Panel data={data} timeMetric={timeMetrics} id={id}/>}
        </Grid>
        <Grid
          item
          md={9}
          lg={10}
          display={{ xs: "none", sm: "none", md: "block", lg: "block" }}
        >
          {id && (
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ height: "800px" }}
              >
                <>
                  <form
                    action={EDITOR_URL + id}
                    encType="multipart/form-data"
                    method="post"
                    target={target}
                    id="collabora-submit-form"
                  >
                    <input
                      name="access_token"
                      value={authToken()}
                      type="hidden"
                      id="access-token"
                    />
                    <input
                      name="css_variables"
                      value="--color-main-text=#000;--color-text-dark=#000;--color-text-darker=#000;--color-text-lighter=#000;--color-main-background=#fff;--color-background-dark=#fff;--color-background-darker=#fff;--color-background-lighter=#fff;"
                      type="hidden"
                    />

                    <input
                      name="ui_defaults"
                      value="UIMode=tabbed;TextRuler=false;TextSidebar=false;"
                      type="hidden"
                    />
                  </form>
                  <Container
                    maxWidth={false}
                    sx={{
                      contain: "layout",
                      height: ["inherit ", "-webkit-fill-available"],
                    }}
                  >
                    <iframe
                      title="Collabora Online Viewer"
                      id="collabora-online-viewer"
                      name="collabora-online-viewer"
                      allowFullScreen={true}
                    ></iframe>
                  </Container>
                </>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Main>
  );
};

export default EvaluateAssignment;
