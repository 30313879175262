import React from "react";
import { Container } from "@mui/material";
import WordChangeLine from "../../elements/graph/wordchange.line";
import HeatmapGraph from "../../elements/graph/heatmap.graph";
import ChangeDistGraph from "../../elements/graph/scattersvg.graph";

const ESSAY_MAP_TAB = "0";
const HEAT_MAP_TAB = "1";
const WORD_CHANGE_TAB = "2";
class InstructorGraph extends React.Component<any, any> {
  // TODO add interface
  constructor(props: any, re: any) {
    super(props);
    this.state = {
      id: props.subid,
      graph: this.props.graph,
    };
  }

  // TODO add interface
  componentDidUpdate(prevProps: any) {
    // Typical usage (don't forget to compare props):
    if (this.props.graph !== prevProps.graph) {
      this.setState({
        graph: this.props.graph,
      });
    }
  }

  render() {
    const { graph, id } = this.state;
    return (
      <React.Fragment>
        <Container
          maxWidth={false}
          sx={{
            contain: "layout",
            height: ["inherit ", "-webkit-fill-available", "85vh"],
          }}
        >
          {graph === ESSAY_MAP_TAB && <ChangeDistGraph subid={id}  />}
          {graph === HEAT_MAP_TAB && <HeatmapGraph subid={id} />}
          {graph === WORD_CHANGE_TAB && <WordChangeLine subid={id} />}
        </Container>
      </React.Fragment>
    );
  }
}

export default InstructorGraph;
