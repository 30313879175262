import React from 'react';
import { GraphProps } from '../../utils/properties';
import MyResponsiveLine from '../../../../elements/graph/line.graph';


const WordChange: React.FC<GraphProps> = ({ data,message }) => {
  return (
    <>
      {data !== undefined ? (
        <MyResponsiveLine data={data} maxY={200}/>
      ) : (
        <p>{message}</p>
      )}
    </>
  );
};

export default WordChange;
