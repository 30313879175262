import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "../../elements/public/main";
import { Grid, Container } from "@mui/material";
import {  validate } from "../../actions/auth";

const InstructorGuide: React.FC = () => {
  const dispatch = useDispatch();
  const userRoles: string[] | undefined = useSelector<any, string[] | undefined>(
    (state) => state.auth.user?.roles
  );
 
  // Check if the user is not logged in or roles are not available
  useEffect(() => {
    // Dispatch the validate action when the component mounts
    // @ts-ignore
    dispatch(validate());
  }, [dispatch]);
  // @ts-ignore
  const isInstructor: boolean = userRoles.includes("ROLE_INSTRUCTOR");
  const iframeUrl: string = process.env.REACT_APP_INST_GUIDE as string;
  if (!isInstructor) {
    return (
      <Main>
        <div>You don't have access to this page.</div>
      </Main>
    );
  }

  return (
    <Main sx={{ padding: 0 }}>
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          md={12}
          xl={12}
          sx={{
            // mt: 1,
            display: "flex",
            flexDirection: "columns",
            height: "650px",
          }}
        >
          <Container
            maxWidth="xl"
            sx={{
              contain: "layout",
              height: "89vh",
              width: "100vw",
              backgroundColor: "white",
            }}
            disableGutters={true}
          >
            <iframe
              id="docs-online-viewer"
              title="student Guide"
              src={iframeUrl}
            ></iframe>
          </Container>
        </Grid>
      </Grid>
    </Main>
  );
};

export default InstructorGuide;
