import React from "react";
import { Feedback } from "./model";
import { Box, Paper, Typography } from "@mui/material";
import { toTimeZoneSimple } from "../../../services/date.service";

interface FeedbackItemProps {
  feedback: Feedback;
  key: string;
}

const FeedbackItem: React.FC<FeedbackItemProps> = ({ feedback, key }) => {
  return (
    <Box>
      <Paper
        elevation={2}
        sx={{
          m: 0,
          mb: "1px",
          minHeight: "50px",
          display: "flex",
          alignItems: "center",
          p: 1,
          backgroundColor: "#29575e2b",
        }}
      >
        {
          <>
            {feedback.comment.split("\n").map((par: string, index: number) => (
              <React.Fragment key={`${key}-${index}`}>
                {par}
                <br />
              </React.Fragment>
            ))}
          </>
        }
      </Paper>
      <Paper elevation={0} sx={{ textAlign: "right" }}>
        <Typography
          variant="caption"
          align="right"
          sx={{ color: "#29575e", mr: 2, mt: 0 }}
        >
          {toTimeZoneSimple(feedback.timestamp.toString())}
        </Typography>
      </Paper>
    </Box>
  );
};

export default FeedbackItem;
