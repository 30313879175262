const shades: { [key: string]: string[] } = {
    warning: [
      "#FFC275", // Lightest yellow-orange
      "#FFC260", // Lightest yellow-orange
      "#E6AC54", // Slightly darker
      "#CC9648", // Darker
      "#B3803C", // Even darker
    ],
    error: [
      "#FF9999", // Lighter shade
      "#FF7373", // Slightly lighter
      "#FF5050", // Central color
      "#E64545", // Slightly darker
      "#CC3B3B", // Darker shade
    ],
    success: [
      "#A8EFD2", // Lightest green
      "#77E3BC", // Slightly lighter
      "#3CD4A0", // Central color
      "#32B886", // Slightly darker
      "#289C6C", // Darker shade
    ],
  };
  
  export const getShade = (index: number, type: 'warning' | 'error' | 'success'): string => {
    const i = 4-(index % 5);
    return shades[type][i];
  };
  
