import React from "react";
import { Typography, Link } from "@mui/material";

export default function CopyRight(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://antecedentwriting.com/">
        Antecedent Writing Analytics
      </Link>{" "}
      {new Date().getFullYear()}
      {". Patent Pending."}
    </Typography>
  );
}
