import React from "react";
import { MenuGroupProps } from "./model";
import {
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";

interface MenuItemProps {
  MenuGroup: MenuGroupProps;
}

const CustomMenuItem: React.FC<MenuItemProps> = ({ MenuGroup }) => {
  return (
    <Grid item xs={12} md={6} lg={4} xl={3}>
      <List
        sx={{ width: "100%", bgcolor: "background.menu" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {MenuGroup.groupLabel}
          </ListSubheader>
        }
      >
        {MenuGroup.options.map((item) => (
          <ListItemButton href={item.link}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItemButton>
        ))}
      </List>
    </Grid>
  );
};

export default CustomMenuItem;
