import Login from "../account/login.component";
import Logout from "../account/logout.component";
import Register from "../account/register.component";
import EnrollInClass from "../classes/EnrollClass.component";
import ClassIndex from "../../containers/instructor/CourseList/ClassIndex.component";
import RegisterClass from "../classes/RegisterClass.component";
import StudentClassPanel from "../../containers/student/Course/Dashboard";
import Home from "../../containers/home/home";
import InstructorClassPanel from "../../containers/instructor/ClassDetails/Dashboard";

import ContactUs from "../contact.component";
import ForgetPassword from "../account/forget-password.component";
import RecoverPassword from "../account/recover-password.component";
import AnalyzeAssignment from "../classes/AnalyzeAssignment.component";
import ReadAssignments from "../../containers/instructor/ReadAssignment/ReadAssignment";
import ShowProgress from "../classes/ShowProgress.component";
import StudentGuide from "../guide/student";
import InstructorGuide from "../guide/instructor";
import ClassList from "../../containers/education/Dashboard/dashboard";
import CourseDetail from "../../containers/education/CourseDetail/Dashboard";
import EvaluateAssignment from "../../containers/education/ReadAssignment/Dashboard";
import StudentAssignmentDashboard from "../../containers/student/Assignment/Dashboard";
import AdminDashboard from "../../containers/admin/Dashboard";
import UserManagemenDashboard from "../../containers/admin/UserManagement/Modification/Dashboard";

import ConfirmEmail from "../account/confirm-email.component";
import VideoGuide from "../guide/video";
import FormatTextDashboad from "../../containers/admin/Submission/FixText/Dashboard";
import SearchSubmission from "../../containers/admin/Submission/Search/Dashboard";
import TermCondition from "../account/term-condtion.component";
import UserProfile from "../../containers/profile/Dashboard";

export const routes = [
  { path: "/", element: <Home /> },
  { path: "home", element: <Home /> },
  { path: "login", element: <Login /> },
  { path: "contactus", element: <ContactUs /> },
  { path: "register", element: <Register /> },
  { path: "Profile", element: <UserProfile /> },
  { path: "logout", element: <Logout /> },
  { path: "RegisterClass", element: <RegisterClass /> },
  { path: "enroll", element: <EnrollInClass /> },
  { path: "classes", element: <ClassIndex /> },
  { path: "forgetpassword", element: <ForgetPassword /> },
  { path: "passwordrecovery/:id", element: <RecoverPassword /> },
  { path: "classdetail/:classid", element: <InstructorClassPanel /> },
  { path: "myclassdetail/:classid", element: <StudentClassPanel /> },
  { path: "assignment/:id", element: <StudentAssignmentDashboard /> },
  { path: "analyze/:id", element: <AnalyzeAssignment /> },
  { path: "readassignment/:id", element: <ReadAssignments /> },
  { path: "progress/:id", element: <ShowProgress /> },
  { path: "studentguide", element: <StudentGuide /> },
  { path: "instructorguide", element: <InstructorGuide /> },
  { path: "educoordinator/courselist", element: <ClassList /> },
  { path: "educoordinator/coursedetail/:id", element: <CourseDetail /> },
  {
    path: "educoordinator/ReadSubmission/:id",
    element: <EvaluateAssignment />,
  },
  { path: "admin/dashboard/", element: <AdminDashboard /> },
  { path: "admin/user/", element: <UserManagemenDashboard /> },
  { path: "admin/VerifyUser/", element: <UserManagemenDashboard /> },
  { path: "admin/fixtext/", element: <FormatTextDashboad /> },
  { path: "admin/fixtext/:id", element: <FormatTextDashboad /> },
  { path: "admin/searchsubmission/", element: <SearchSubmission /> },

  { path: "confirmemail/:id", element: <ConfirmEmail /> },
  { path: "videoguide", element: <VideoGuide /> },
  { path: "legal/:type", element: <TermCondition /> },
];
