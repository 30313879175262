import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { CONSTRAINTS } from "./constraints";
import { SupportRequest, Tracker } from "./model";
import authHeader from "../../services/auth-header";

export const getProjectTrackers = async () => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "GET",
    url: `${CONSTRAINTS.HOST}/${CONSTRAINTS.SUPPORT}/${CONSTRAINTS.TRACKERS}`,
    params: {},
    data: {},
  };
  try {
    const apiCall: AxiosResponse<Tracker[]> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const createIssue = async (SupportRequest: SupportRequest) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${CONSTRAINTS.HOST}/${CONSTRAINTS.SUPPORT}/${CONSTRAINTS.ISSUES}`,
    params: {},
    data: SupportRequest,
  };
  try {
    const apiCall: AxiosResponse<Tracker[]> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};
