/**
 * Students Videos Config
 */
const StudentVideoConfig = {
  header: "Getting started with your first class (Student)",

  videoUrl:
    "https://www.youtube-nocookie.com/embed/7SCN9ywOldI?si=ezaT_hP7kElzLxnO",
  title: "Getting started with your first class (Student)",
  allow:
    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
};

/**
 * Instructors Videos Config
 */

const InstructorStartConfig = {
  header: "Getting started with your first class (Instructor)",
  videoUrl:
    "https://www.youtube-nocookie.com/embed/pka8Q0wOsiw?si=MWIKJMjy_QTLGF4O",
  title: "Getting started with your first class (Instructor)",
  allow:
    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
};

const GraphAnalysisConfig = {
  header: "Interpreting Analytic graphs",
  videoUrl: "https://www.youtube.com/embed/6bA03VW61iI?si=5YHEcYcnWKCJq6W2",
  title: "Interpreting Analytic graphs",
  allow:
    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
};

const RewindConfig = {
  header: "Using the Rewind feature",
  videoUrl: "https://www.youtube.com/embed/TiBG89e5Gvw?si=x7kEiCSyB1jQ30fW",
  title: "Using the Rewind feature",
  allow:
    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
};

const EssayMapConfig = {
  header: "Interpreting EssayMaps",
  videoUrl: "https://www.youtube.com/embed/Vi-W6iX0nzo?si=xg2TaZMHQ9S5j2bH",
  title: "Interpreting EssayMaps",
  allow:
    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
};
const SubmissionTimelineConfig = {
  header: "Understanding the Submission Timeline",
  videoUrl: "https://www.youtube.com/embed/jNhGVSuCNMs?si=v-2wQc9wciHCtdzX",
  title: "Understanding the Submission Timeline",
  allow:
    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
};

/**
 * Exporting video Lists Videos Config
 */

export const StudentVideos = [StudentVideoConfig];
export const InstructorVideos = [
  InstructorStartConfig,
  GraphAnalysisConfig,
  RewindConfig,
  EssayMapConfig,
  SubmissionTimelineConfig,
];
