import React, { useEffect, useState } from "react";
import Main from "../../../../elements/public/main";
import SubmissionSearch from "./SubmissionSearch";
import { SearchProps, initSearchData } from "./model";
import { validateSubmissionId } from "./services";
import authToken from "../../../../services/auth-token";
import { Container, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { validate } from "../../../../actions/auth";

const FormatTextDashboad = () => {
  const dispatch = useDispatch();

  // @ts-ignore
  dispatch(validate());
  const [valid, setValid] = useState<boolean>(false);
  const { id } = useParams();
  const [searchData, setSearchData] = useState<SearchProps>(
    id ? { ...initSearchData, submissionId: id } : initSearchData
  );
  const reloadDocument = () => {
    // TODO add interface
    const formformExists: any = document.getElementById(
      "collabora-submit-form"
    );

    if (formformExists) {
      formformExists.submit();
    }
  };
  const handleLoad = async () => {
    const response = await validateSubmissionId(searchData.submissionId);
    if (response.status === 200 && response.data) {
      setValid(true);
    }
  };

  useEffect(() => {
    if (id) handleLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (valid) reloadDocument();
  }, [valid]);

  const EDITOR_URL = process.env.REACT_APP_ADMIN_EDITOR_URL;

  return (
    <Main>
      <SubmissionSearch
        searchData={searchData}
        setSearchData={setSearchData}
        handleLoad={handleLoad}
      />
      {valid && (
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ height: "88vh" }}>
          <form
            action={EDITOR_URL + searchData.submissionId}
            encType="multipart/form-data"
            method="post"
            target="collabora-online-viewer"
            id="collabora-submit-form"
          >
            <input
              name="access_token"
              value={authToken()}
              type="hidden"
              id="access-token"
            />
            <input
              name="css_variables"
              value="--color-main-text=#000;--color-text-dark=#000;--color-text-darker=#000;--color-text-lighter=#000;--color-main-background=#fff;--color-background-dark=#fff;--color-background-darker=#fff;--color-background-lighter=#fff;"
              type="hidden"
            />

            <input
              name="ui_defaults"
              value="UIMode=tabbed;TextRuler=false;TextSidebar=false;"
              type="hidden"
            />
          </form>
          <Container
            maxWidth={false}
            sx={{
              contain: "layout",
              height: ["inherit ", "-webkit-fill-available"],
            }}
          >
            <iframe
              title="Collabora Online Viewer"
              id="collabora-online-viewer"
              name="collabora-online-viewer"
              allowFullScreen={true}
            ></iframe>
          </Container>
        </Grid>
      )}
    </Main>
  );
};

export default FormatTextDashboad;
