export const apiIds = {
  getSubmissionData: "getSubmissionData",
  getTimeMetrics: "getTimeMetrics",
  reOpenSubmission: "reOpenSubmission",
  finalizeSubmission: "finalizeSubmission",
  evaluateSubmission: "evaluateSubmission",
  gradeSubmission: "gradeSubmission",
  getSubmissionLogs: "getSubmissionLogs",
  addNewFeedback:"addNewFeedback",
  endCommenting:"endCommenting",
  draftSubmit:"draftSubmit",
  draftGrading:"draftGrading",
  draftPermission: "draftPermission",
};
