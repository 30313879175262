import React from "react";
import { GraphProps } from "../../utils/properties";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const HeatMap: React.FC<GraphProps> = ({ data,message }) => {
  const graphoptions: ApexOptions = {
    chart: {
      height: 600,
      type: "heatmap",
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#008FFB"],
    title: {
      text: "HeatMap",
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        radius: 0,
        distributed: true,
        useFillColorAsStroke: true,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 40,
              name: "low",
              color: "#B9B9B9",
            },
            {
              from: 40,
              to: 100,
              name: "medium",
              color: "#128FD9",
            },
            {
              from: 100,
              to: 400,
              name: "high",
              color: "#3CD4A0",
            },
            {
              from: 400,
              to: 6000,
              name: "extreme",
              color: "#FF0000",
            },
          ],
        },
      },
    },
  };

  return (
    <>
      {data ? (
        <ReactApexChart
          options={graphoptions}
          series={data}
          type="heatmap"
          height={600}
        />
      ) : (
        <p>{message}</p>
      )}
    </>
  );
};

export default HeatMap;
